import React from "react";
import ImageLogo from "../../../../src/static/images/logo.png";
import { Box, Typography } from "@mui/material";
// import BurgerImage from "../../../constants/DrImages/Glyph_ undefined.png";
import "./About.scss";
import VisualImage from "../AboutImages/Visual.png";
// import Vision from "../../../constants/DrImages/vision.png";
// import Goal from "../../../constants/DrImages/goal.png";
// import Inspiring from "../../../constants/DrImages/inspiring.png";
import SillyImage from "../AboutImages/sillyimages.png";
import Alldoctor from "../AboutImages/Alldoctors.png";
import Whitelogo from "../AboutImages/whiteFrame.png";
import { LinkedIn } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Facebook } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton";
import { Link, useNavigate } from "react-router-dom";

const AboutLanding = () => {
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/selectprofiletype");
    };
    return (
        <>
            <div className="screen-container1">
                <div className="header-container1">
                    <Link to={"/"}>
                        {" "}
                        <Box component={"img"} src={ImageLogo}></Box>
                    </Link>
                    <div className="other-header-content">
                        <Link to={"/howitworks"} style={{ textDecoration: "none" }}>
                            <Typography style={{ color: "black" }}>How it works?</Typography>
                        </Link>
                        <Typography>About Us</Typography>
                        <Typography>Pricing</Typography>
                        <CustomButton
                            label="Sign Up"
                            buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                            isTransaprent={"True"}
                            handleClick={() => {
                                navigate("/selectsignup");
                            }}
                        ></CustomButton>
                        <CustomButton
                            label="Login"
                            buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                            handleClick={handleSubmit}
                        ></CustomButton>
                    </div>
                </div>
                <div className="about-heading">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "Playfair Display",
                            fontSize: "64px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "94px",
                        }}
                    >
                        About Us
                    </Typography>
                </div>

                <div className="Heading-about">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "Playfair Display",
                            fontSize: "34px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "46px",
                        }}
                    >
                        HEALING HUMANITY THROUGH SCIENCE AND COMPASSION, ONE PATIENT AT A TIME
                    </Typography>
                </div>
                <div className="text9">
                    <Typography
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        "Share e Care" is a healthcare website that focuses on providing innovative
                        and accessible healthcare solutions to individuals and communities. Our
                        mission is to bridge the gap between patients and healthcare providers by
                        leveraging technology to enhance the overall healthcare experience. Our
                        mission is to create a more connected, efficient, and accessible healthcare
                        ecosystem. Our platform is dedicated to enhancing patient-doctor
                        relationships, streamlining diagnostics and treatment, and ultimately
                        improving health outcomes. we are committed to prioritizing user privacy and
                        data security. We adhere to the highest industry standards to ensure that
                        personal health information is protected and used only for legitimate
                        healthcare purposes.
                    </Typography>
                </div>
                <div className="left-right-content">
                    <div className="left-content">
                        <div className="patient-module">
                            <Typography
                                style={{
                                    color: "#313033",
                                    fontFamily: "Playfair Display",
                                    fontSize: "34px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "46px",
                                }}
                            >
                                Patient Module
                            </Typography>
                            <div className="About-Patient">
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Access Personal Health Records
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Book Appointments Online.
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Monitor Vital Signs and Health Metrics
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Connect with a Community of Support
                                </Typography>
                            </div>
                        </div>
                        <div className="patient-module">
                            <Typography
                                style={{
                                    color: "#313033",
                                    fontFamily: "Playfair Display",
                                    fontSize: "34px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "46px",
                                }}
                            >
                                Doctor Module
                            </Typography>
                            <div className="About-Patient">
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Virtual Consultations.
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Access Patient Records
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Prescriptions Management
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Collaborate with Colleagues
                                </Typography>
                            </div>
                        </div>
                        <div className="patient-module">
                            <Typography
                                style={{
                                    color: "#313033",
                                    fontFamily: "Playfair Display",
                                    fontSize: "34px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "46px",
                                }}
                            >
                                HCF Module
                            </Typography>
                            <div className="About-Patient">
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Appointment Management
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Diagnostic Reports and Results
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Secure Data Storage
                                </Typography>
                                <Typography
                                    style={{
                                        color: "#525152",
                                        fontFamily: "Red Hat Display",
                                        fontSize: "28px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "50px",
                                    }}
                                >
                                    &#8226;Integration Capabilities
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="visualImage">
                        <Box
                            component={"img"}
                            src={VisualImage}
                            style={{ width: "626px", height: "765px" }}
                        ></Box>
                    </div>
                </div>

                <div className="heading4">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "Playfair Display",
                            fontSize: "66px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "46px",
                        }}
                    >
                        OUR MISSION
                    </Typography>
                </div>
                <div className="text9">
                    <Typography
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        Our mission is to transform the healthcare landscape by breaking down
                        traditional barriers and making healthcare accessible, convenient, and
                        <br></br> efficient. We are committed to providing a platform where
                        individuals can access the care they need when they need it, promoting a
                        healthier and
                        <br></br>
                        happier society
                    </Typography>
                </div>
                <div className="Sillyimage">
                    <Box
                        component={"img"}
                        src={SillyImage}
                        style={{ width: "700px", height: "300px" }}
                    ></Box>
                </div>
                <div className="pink-container">
                    <div className="boximage">
                        <Box
                            component={"img"}
                            src={Alldoctor}
                            style={{ width: "500px", height: "450px" }}
                        ></Box>
                    </div>
                    <div className="welcome-heading">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "36px",
                            }}
                        >
                            Welcome to our medical app,<br></br>
                            where your health is our priority!
                        </Typography>
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Meet our exceptional team behind the innovative healthcare app<br></br>
                            that is revolutionizing the way you manage your health. <br></br>
                            Comprised of dedicated professionals from various fields, we are
                            <br></br>
                            united by a common goal: to provide you with the best possible <br></br>
                            care and support on your wellness journey.
                        </Typography>
                    </div>
                </div>
                <div className="red-footer">
                    <div className="first-ali">
                        <div className="first-half">
                            <Box
                                component={"img"}
                                src={Whitelogo}
                                style={{ width: "245px", height: "42px" }}
                            ></Box>
                        </div>
                        <div className="half-align">
                            <Typography
                                style={{
                                    color: "#FFF",
                                    fontFamily: "Red Hat Display ",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                }}
                            >
                                Doctor Registration
                            </Typography>
                            <Typography
                                style={{
                                    color: "#FFF",
                                    fontFamily: "Red Hat Display ",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                }}
                            >
                                How it Works?
                            </Typography>
                        </div>
                    </div>
                    <div className="hcf-pr">
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            HCF Registration
                        </Typography>
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            Pricing
                        </Typography>
                    </div>

                    <div className="about-us">
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            About Us
                        </Typography>
                    </div>
                    <div className="social-icons">
                        <LinkedIn style={{ color: "white" }} />
                        <Twitter style={{ color: "white" }} />
                        <Instagram style={{ color: "white" }} />
                        <Facebook style={{ color: "white" }} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default AboutLanding;
