/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "@material-ui/core";
import { data, CallCardData, baseURL } from "../../constants/const";
import Drcard from "../../constants/drcard/drcard";
import CustomButton from "../../components/CustomButton/custom-button";
import SingleLineGridList from "./Crousal";
import "./Explore.scss";
import { NavLink } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../config/axiosInstance";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Explore = () => {
    const [cardData, setCardData] = useState([]);
    const [hcfData, setHCFData] = useState([]);
    const [nav_specialization, setNav_spelization] = useState([]);
    const [specializationDoc, setSpecializationDoc] = useState("CARDIOLOGIST");
    const [specializationCardData, setspecializationCardData] = useState("");

    const fetchDataNew = async () => {
        try {
            const response = await axiosInstance.get("/sec/patient/DashboardDoctordetail");
            // const response = await axios.get(`${baseURL}/sec/patient/DashboardDoctordetail`);
            // console.log("Fetch the response : ", response?.data?.response);
            setCardData(response?.data?.response);
        } catch (error) {
            console.log(error.response);
        }
    };

    const fetchDataHCFCards = async () => {
        try {
            const response = await axiosInstance.get("/sec/patient/DashboardHcfdetails");
            // const response = await axios.get(`${baseURL}/sec/patient/DashboardHcfdetails`);
            // console.log("Fetch the response : ", response?.data?.response);
            setHCFData(response?.data?.response);
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        // fetchData();
        localStorage.setItem("activeComponent", "dashboard");
        fetchDataNew();
        fetchDataHCFCards();
    }, []);

    const navSpecializtion = async () => {
        try {
            const resp = await axiosInstance(`/sec/patient/doctorDeptartments`);
            // console.log(resp?.data?.response);
            setNav_spelization(resp?.data?.response);
        } catch (err) {
            console.log("Nav specialization error : ", err);
        }
    };

    useEffect(() => {
        navSpecializtion();
    }, []);

    const scrollContainerRef = useRef(null);

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
        }
    };

    const DoctorSpecialization = async (specialist) => {
        try {
            const response = await axiosInstance(`/sec/patient/getdoctorsByDept/${specialist}`);
            console.log("specialization : ", response?.data?.response);
            setspecializationCardData(response?.data?.response[`${specializationDoc}`]);
        } catch (err) {
            console.log("specialization error : ", err);
        }
    };

    useEffect(() => {
        if (specializationDoc != "") {
            DoctorSpecialization(specializationDoc);
        }
    }, [specializationDoc]);

    console.log("Spec : ", specializationCardData);
    console.log("specializationDoc : ", specializationDoc);
    if (!cardData) return null;

    return  (
        <Box sx={{ width: "90%" }}>
            <Box className="NavBar-Box" sx={{ marginLeft: 0, marginBottom: 0 }}>
                <NavLink to={"/patientdashboard/dashboard/explore"}>Explore</NavLink>
                <NavLink to={"/patientdashboard/dashboard/myactivity"}>My Activity</NavLink>
            </Box>
            <Box sx={{ width: "100%" }}>
                {/* Horozontal slider starts */}
                <Box sx={{ width: "100%", height: "fit-content", overflow: "hidden" }}>
                    <SingleLineGridList />
                </Box>
                {/* Popular Field starts */}
                <CallCardData
                    linkPath={`/patientdashboard/drdetailscard/`}
                    sendCardData={cardData}
                    CardData={data}
                    textField={"Popular"}
                />
                {/* Featured Fields starts */}
                <CallCardData
                    linkPath={`/patientdashboard/drdetailscard/`}
                    sendCardData={cardData}
                    CardData={data}
                    textField={"Featured"}
                />
                {/* Category component starts */}
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <div onClick={handleScrollLeft}>
                            {" "}
                            {/* Add left arrow */}
                            <ChevronLeftIcon />
                        </div>
                        <Box
                            sx={{ display: "flex", position: "relative" }} // Add relative positioning
                            className={"horizontal-scroll-container NavBar-Container-one"}
                        >
                            <div
                                ref={scrollContainerRef}
                                style={{ overflowX: "auto", display: "flex" }}
                            >
                            
                                {nav_specialization.map((specialization, index) => (
                                    <CustomButton
                                        key={index}
                                        to={`/patientdashboard/${specialization?.department_name.toLowerCase()}`}
                                        label={`${specialization?.department_name}`}
                                        isTransaprent={
                                            specialization.department_name.toLowerCase() ===
                                            specializationDoc.toLowerCase()
                                                ? false
                                                : true
                                        }
                                        buttonCss={{
                                            borderRadius: "50px",
                                            padding: "0 6.5%",
                                            marginRight: "1%",
                                            whiteSpace: "normal",
                                        }}
                                        handleClick={() => {
                                            setSpecializationDoc(specialization?.department_name);
                                        }}
                                    >
                                    </CustomButton>
                                ))}
                                {/* <NavLink to={"/more"}>More...</NavLink> */}
                            </div>
                        </Box>
                        <div onClick={handleScrollRight}>
                            {" "}
                            {/* Add right arrow */}
                            <ChevronRightIcon />
                        </div>
                    </Box>
                    <CallCardData
                        linkPath={`/patientdashboard/drdetailscard/`}
                        sendCardData={
                            specializationCardData === "" ? specializationCardData : specializationCardData
                        }
                        // CardData={data}
                        textField={""}
                    />
                </Box>
                {/* Near you component starts */}
                <CallCardData
                    linkPath={`/patientdashboard/drdetailscard/`}
                    sendCardData={cardData}
                    CardData={data}
                    textField={"Near You"}
                />
                {/* Near you component ends */}

                {/* Hcf Cards component starts */}
                <CallCardData
                    linkPath={`/patientdashboard/hcfDetailCard/`}
                    sendCardData={hcfData}
                    CardData={data}
                    textField={"HCF Card Component"}
                />
                {/* hcf card component ends */}
            </Box>
        </Box>
    );
};

export default Explore;
