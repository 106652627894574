/* eslint-disable semi */
/* eslint-disable space-before-blocks */
/* eslint-disable prettier/prettier */
// import React from 'react'
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import "./hcfadminlogin.scss";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosInstance from "../../../config/axiosInstance";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton/custom-button";
import { useAuthentication } from "../../../loginComponent/UserProvider";
import CustomSnackBar from "../../../components/CustomSnackBar/custom-sack-bar";
import { baseURL, emailRegex, passwordRegex } from "../../../constants/const";

const HcfAdminLogin = () => {
    const [showPassword, setShowPassword] = useState(true);
    const [islogin, setIslogin] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [showSnack, setShowSnack] = useState(false);
    const [helperTextMessage, setHelperTextMessage] = useState(false);
    const [passwordHelperTextMessage, setPasswordHelperTextMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const [data, setData] = useState({
        email: null,
        password: null,
        login_with_email: true,
        mobile: null,
        role_id: 2
    });

    const navigate = useNavigate();
    const Authentication = useAuthentication();

    const fetchData = async () => {
        console.log("Entered the fetch data");
        setShowSnack(true);
        setErrorMessageOpen(false);
        setShowError(false);

        // navigate("/skelatonLoader" , {replace : true});
        try {
            // const response = await axiosInstance.post("/sec/auth/login",JSON.stringify(data),{"Accept" : "Application/json"});
            const response = await axios.post(`${baseURL}/sec/auth/login`, JSON.stringify(data), {
                Accept: "Application/json",
            });
            console.log("Response : ", response);
            localStorage.setItem("hcfadmin_Email", data?.email);
            if (response?.data?.response?.message === "INCOMPLETE_PROFILE") {
                alert("sign up change route");
                // navigate("/patientdashboard", { replace: true });

                // navigate("/patientcompleteprofile");
            } else if (data.email == null || data.password == null) {
                // alert("some error occur please try later");
                alert("please enter username and password");
            } else if (response?.data?.response?.access_token) {
                console.log("hcf admin response  : ", response?.data?.response?.message);
                console.log("email : ", response?.data?.response?.email);
                console.log("access_token : ", response?.data?.response?.access_token);
                console.log("hcfadmin suid : ", response?.data?.response?.suid);

                localStorage.setItem("access_token", response?.data?.response?.access_token);
                localStorage.setItem("hcfadmin_suid", response?.data?.response?.suid);

                Cookies.set("clinic_uid", response?.data?.response?.suid);
                Cookies.set("token", response?.data?.response?.access_token);
                // Cookies.set("token", response?.data?.response?.access_token);
                Cookies.set("hcfadmin_Email", response?.data?.response?.email);
                Authentication.PatientLogin(email);
                // alert("succesfully loggedIn");
                // navigate('/patientverification' , {replace : true});
                console.log("navigate to clinic");
                setIslogin(false);
                navigate("/hcfadmin/notification", { replace: true });
                setErrorMessageOpen(false);
            } else {
                setShowError(true);
            }
        } catch (error) {
            console.log(error);
            // alert("Enter Correct username and password", error?.response?.data?.error);
            setErrorMessageOpen(true);
            setErrorMessage(error?.response?.data?.error);
            setShowSnack(false);
            // Add Snack bar
            setEmail(null);
            setPassword(null);
            setIslogin(false);
        }
    };

    // useEffect(() => {
    //     if (islogin === true) {
    //         fetchData();
    //     }
    // }, [islogin]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // if (email === null && password === null) {
        //     alert("Email and Password");
        //     setIslogin(false);
        //     return false;
        // }
        // else if(regularExpression.test(password)){
        //     alert("password should contain atleast one number and one special character");
        //     return false;
        // }
        setData({
            email: email,
            password: password,
            // mobile: "9994483286",
            login_with_email: true,
            role_id: 2
        });
        // setIslogin(!islogin);
        fetchData();
        return null;
    };

    console.log("Patient Data : ", data);

    return (
        <div className="register-photo">
            <CustomSnackBar
                isOpen={showError}
                // actionLabel={"action"}
                // handleAction={() => setShowSnack(true)}
                message={"some error occur please try later"}
                type="error"
            />

            <CustomSnackBar
                isOpen={showSnack}
                // actionLabel={"action"}
                // handleAction={() => setShowSnack(true)}
                message={"Please Wait while we are logging you in"}
                type="success"
            />

            <CustomSnackBar
                isOpen={errorMessageOpen}
                // actionLabel={"action"}
                // handleAction={() => setShowSnack(true)}
                message={errorMessage}
                type="error"
            />
            <div className="form-container">
                <div className="image-holder"></div>

                <div>
                    <div className="logo1">
                        <img src="images/logo.png" alt="Logo" width="200" />
                    </div>

                    <h2 className="text-center">
                        <strong>HCF Admin Login</strong>
                    </h2>

                    <div className="component-library ">
                        <div className="items ">
                            <div className="field-center">
                                <Stack spacing={10} alignItems="center" flexDirection="column">
                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Email"}
                                        defaultValue={data?.email}
                                        helperText={
                                            helperTextMessage ? "Valid Email" : "InValid Email"
                                        }
                                        // isInvalid
                                        // isValid
                                        onChange={(event) => {
                                            if (event?.target?.value.match(emailRegex)) {
                                                console.log("Email verified");
                                                setHelperTextMessage(true);
                                                setData({ ...data, email: event?.target?.value });
                                            } else {
                                                console.log("Email not verified");
                                                setHelperTextMessage(false);
                                            }
                                            // setData({...data , email : event?.target?.value })
                                            setEmail(event.target.value);
                                        }}
                                        textcss={{
                                            width: "22.5em",
                                            height: "56px",
                                        }}
                                    />
                                </Stack>

                                <Stack
                                    spacing={10}
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{ marginTop: "2%" }}
                                >
                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Password"}
                                        defaultValue={data?.password}
                                        helperText={
                                            passwordHelperTextMessage
                                                ? "Validated Password"
                                                : "inValid Password"
                                        }
                                        type={showPassword ? "password" : "text"}
                                        // isValid
                                        onChange={(event) => {
                                            if (event?.target?.value.match(passwordRegex)) {
                                                console.log("password verified");
                                                setPasswordHelperTextMessage(true);
                                                setData({
                                                    ...data,
                                                    password: event?.target?.value,
                                                });
                                            } else {
                                                console.log(
                                                    "should contain one lowercase , uppercase alphabet , number , special character and length should not be less than 8 characters",
                                                );
                                                setPasswordHelperTextMessage(false);
                                            }
                                        }}
                                        // inputType={"password"}
                                        textcss={{
                                            width: "22.5em",
                                            height: "56px",
                                        }}
                                        rightIcon={
                                            showPassword ? (
                                                <VisibilityIcon
                                                    onClick={() => setShowPassword(false)}
                                                />
                                            ) : (
                                                <VisibilityOffIcon
                                                    onClick={() => setShowPassword(true)}
                                                />
                                            )
                                        }
                                    />
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <CustomButton
                            label={"Log In"}
                            isTransaprent={false}
                            isDisabled={false}
                            isElevated={false}
                            handleClick={fetchData}
                            buttonCss={{
                                width: "25.8em",
                                height: "3.5em",
                                padding: "8px 16px",
                                justifyContent: "center",
                                alignItems: "center",

                                borderRadius: "100px",
                                marginLeft: "-70px",
                                marginTop: "37px",
                            }}
                        />
                    </div>
                    <div className="forgotpassword">
                        <Link to="/patientforgotpassword" className="link">
                            Forgot Password
                        </Link>
                    </div>
                    <div className="mobile">
                        <Link to="/patientloginwithotp" className="link">
                            Log In with OTP
                        </Link>
                    </div>
                    <div className="already">
                        I Don&apos;t have an account &nbsp;
                        <Link to="/patientsignup" className="link">
                            Create Account
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HcfAdminLogin;
