import React, { useEffect, useRef, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Container5 from "../Container5";
import Container3 from "../Container3";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import axiosInstance from "../../../../../config/axiosInstance";
import CustomButton from "../../../../../components/CustomButton";
import { HCFCardsData } from "../../../../../constants/const";

const Labs = () => {
    const ID = useParams();
    console.log(ID.hcfID)
    const [nav_specialization, setNav_spelization] = useState([]);
    const scrollContainerRef = useRef(null);
    const [specializationHCF, setspecializationHCF] = useState("Microbiology");
    const [specializationCardData,setSpecializationCardData] = useState([])

    const navSpecializtion = async () => {
        try {
            const resp = await axiosInstance(`/sec/labDepartments`);
            // console.log(resp?.data?.response);
            setNav_spelization(resp?.data?.response);
        } catch (err) {
            console.log("Nav specialization error : ", err);
        }
    };

    useEffect(() => {
        navSpecializtion();
    }, []);

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
        }
    };

    
    const HCFLabDoctor = async () => {
        try {
            const resp = await axiosInstance(`/sec/patient/SingleLabFilters/${specializationHCF}/${ID.hcfID}`);
            console.log("specializationHCF : ",specializationHCF)
            console.log("Immunology : ",resp?.data?.response[specializationHCF]);
            setSpecializationCardData(resp?.data?.response[specializationHCF]);
        } catch (err) {
            setSpecializationCardData([]);
            console.log("Nav specialization error : ", err);
        }
    };

    useEffect( () => {
        HCFLabDoctor();
    },[specializationHCF] )
    

    return (
        <>
            <nav className="NavBar-Container-Appoinement">
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/about`}>About</NavLink>
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/department`}>
                    Department
                </NavLink>
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/labs`}>Labs</NavLink>
            </nav>
            <div className="about-data" style={{ marginTop: "4rem", width: "100%" }}>
                {/* <Container5 /> */}

                {/* Category component starts */}
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <div onClick={handleScrollLeft}>
                            {" "}
                            {/* Add left arrow */}
                            <ChevronLeftIcon />
                        </div>
                        <Box
                            sx={{ display: "flex", position: "relative", width: "100%" }} // Add relative positioning
                            className={"horizontal-scroll-container NavBar-Container-one"}
                        >
                            <div
                                ref={scrollContainerRef}
                                style={{ overflowX: "auto", display: "flex" }}
                            >
                                {nav_specialization.map((specialization, index) => (
                                    <CustomButton
                                        key={index}
                                        to={`/patientdashboard/${specialization?.lab_department_name.toLowerCase()}`}
                                        label={`${specialization?.lab_department_name.toLowerCase()}`}
                                        isTransaprent={
                                            specialization.lab_department_name.toLowerCase() ===
                                            specializationHCF.toLowerCase()
                                                ? false
                                                : true
                                        }
                                        buttonCss={{
                                            borderRadius: "50px",
                                            padding: "0 6.5%",
                                            marginRight: "1%",
                                            whiteSpace: "normal",
                                            textWrap: "nowrap",
                                        }}
                                        handleClick={() => {
                                            console.log("specialization?.lab_department_name :",specialization?.lab_department_name)
                                            console.log("specialization?.lab_department_name :",specialization)
                                            setspecializationHCF(
                                                specialization?.lab_department_name,
                                            );
                                        }}
                                    ></CustomButton>
                                ))}
                                {/* <NavLink to={"/more"}>More...</NavLink> */}
                            </div>
                        </Box>
                        <div onClick={handleScrollRight}>
                            {" "}
                            {/* Add right arrow */}
                            <ChevronRightIcon />
                        </div>
                    </Box>
                    <HCFCardsData
                        // linkPath={`/patientdashboard/drdetailscard/`}
                        sendCardData={
                            specializationCardData === "" ? specializationCardData : specializationCardData
                        }
                        // CardData={data}
                        // textField={""}
                    />
                </Box>

                {/* <Container3 /> */}
            </div>
        </>
    );
};

export default Labs;
