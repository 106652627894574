import React, { useEffect, useState } from "react";
import "./received.scss";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RecieveTable from "../../PatientManage/Reports/Received/ReceiveTable";
import { PaginationCard } from "../../PatientAppointment/PatientCards";
import CustomButton from "../../../components/CustomButton";
import axiosInstance from "../../../config/axiosInstance";

function createData(name, calories, fat, carbs, protein, action, plan, type) {
    return { name, calories, fat, carbs, protein, action, plan, type };
}
// const rows = [
//     createData(
//         <Typography>Radiology.pdf</Typography>,
//         <RecieveTable name={"Apollo Diag"} Id={"TST00012"} />,
//         <Typography style={{ color: "gray" }}>19:00, 23-10-23</Typography>,
//         <Typography style={{ color: "gray" }}>Radiology</Typography>,

//         <Typography style={{ color: "#E72B4A" }}>View</Typography>,
//         <FileDownloadIcon style={{ color: "#E72B4A" }}></FileDownloadIcon>,

//         <div>
//             <CustomButton label="share" isTransaprent />
//         </div>,
//     ),
//     createData(
//         <Typography>Radiology.pdf</Typography>,
//         <RecieveTable name={"Apollo diag"} Id={"TST00012"} />,
//         <Typography style={{ color: "gray" }}>19:00, 23-10-23</Typography>,
//         <Typography style={{ color: "gray" }}>Radiology</Typography>,

//         <Typography style={{ color: "#E72B4A" }}>View</Typography>,
//         <FileDownloadIcon style={{ color: "#E72B4A" }}></FileDownloadIcon>,

//         <div>
//             <CustomButton label="share" isTransaprent />
//         </div>,
//     ),

//     createData(
//         <Typography>Radiology.pdf</Typography>,
//         <RecieveTable name={"Apollo Diag"} Id={"TST00012"} />,
//         <Typography style={{ color: "gray" }}>19:00, 23-10-23</Typography>,
//         <Typography style={{ color: "gray" }}>Radiology</Typography>,

//         <Typography style={{ color: "#E72B4A" }}>View</Typography>,
//         <FileDownloadIcon style={{ color: "#E72B4A" }}></FileDownloadIcon>,

//         <div>
//             <CustomButton label="share" isTransaprent />
//         </div>,
//     ),

//     createData(
//         <Typography>Radiology.pdf</Typography>,
//         <RecieveTable name={"Apollo Diag"} Id={"TST00012"} />,
//         <Typography style={{ color: "gray" }}>19:00, 23-10-23</Typography>,
//         <Typography style={{ color: "gray" }}>Radiology</Typography>,

//         <Typography style={{ color: "#E72B4A" }}>View</Typography>,
//         <FileDownloadIcon style={{ color: "#E72B4A" }}></FileDownloadIcon>,

//         <div>
//             <CustomButton label="share" isTransaprent />
//         </div>,
//     ),
// ];

const Received = () => {
    const [value, setValue] = useState([null, null]);
    const [tableData, setTableData] = useState([]);
    const [patientID, setPatientID] = React.useState(localStorage.getItem("patient_suid"));

    function getWeeksAfter(date, amount) {
        return date ? date.add(amount, "week") : undefined;
    }

    const FetchData = async () => {
        try {
            const response = await axiosInstance.post(
                "/sec/patient/ReportsReceived",
                JSON.stringify({
                    patient_id: patientID,
                    status: "completed",
                }),
            );
            console.log(response?.data?.response);
            setTableData(response?.data?.response);
        } catch (error) {
            console.log("Error : ", error);
        }
    };

    useEffect(() => {
        FetchData();
    }, []);

    return (
        <>
            {/* <Box
                sx={{
                    position: "absolute",
                    width: "40%",
                    left: "58%",
                    top: "-20%",
                }}
            > */}
                {/* Date range picker */}
                {/* <Box sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            disablePast
                            value={value}
                            maxDate={getWeeksAfter(value[0], 4)}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </Box> */}
            {/* </Box> */}
                <Box className="allfile-main-container">
            <Box>
                <TableContainer component={Paper} style={{ background: "white" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name/Lab</TableCell>
                                <TableCell>Lab/Booking ID</TableCell>
                                <TableCell align="right">Date&Time</TableCell>
                                <TableCell align="right">Category</TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                {/* <TableCell align="right">Action</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row) => (
                                <TableRow
                                    key={row?.BookingID}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row?.test_files}
                                    </TableCell>
                                    <TableCell align="right">
                                        <RecieveTable name={row?.hcf_diag_name} Id={row?.BookingID} />,
                                    </TableCell>
                                    <TableCell align="right">{`${row?.Scheduled?.split("T")[0]} | ${row?.Scheduled?.split("T")[1]?.split(".")[0]} `}</TableCell>
                                    <TableCell align="right">{row?.labName}</TableCell>
                                    <TableCell align="right">{row.protein}</TableCell>
                                    <TableCell align="right">{row.action}</TableCell>
                                    <TableCell align="right">{row.plan}</TableCell>
                                    <TableCell align="right">{row.type}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box>
                <PaginationCard />
            </Box>
            </Box>
        </>
    );
};

export default Received;
