import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import Container5 from "../Container5";
import Container3 from "../Container3";
import { baseURL, CallCardData } from "../../../../../constants/const";
import { Box } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axiosInstance from "../../../../../config/axiosInstance";
import CustomButton from "../../../../../components/CustomButton";

const Department = () => {
    const [departData, setDepartData] = useState([]);
    const [nav_specialization, setNav_spelization] = useState([]);
    const [specializationCardData, setspecializationCardData] = useState("");
    const [specializationDoc, setSpecializationDoc] = useState("CARDIOLOGIST");

    const ID = useParams();

    const getDeparthcf = async () => {
        try {
            const response = await axios.get(`${baseURL}/sec/patient/HcfDeptDocdetails/26`);
            setDepartData(response.data?.response ?? []);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getDeparthcf();
        FetchData();

        console.log("Working Doctor request");
    }, []);

    const [departmentType, setDepartmentType] = useState([]);

    const FetchData = async () => {
        try {
            const response = await axiosInstance("/sec/departments");
            console.log("Department Type : ", response?.data?.response);
            // setDepartmentType()
        } catch (error) {
            console.log(error);
        }
    };

    const scrollContainerRef = useRef(null);

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
        }
    };

    const DoctorSpecialization = async (specialist) => {
        try {
            const response = await axiosInstance(`/sec/patient/getdoctorsByDept/${specialist}`);
            console.log("specialization : ", response?.data?.response);
            setspecializationCardData(response?.data?.response[`${specializationDoc}`]);
        } catch (err) {
            console.log("specialization error : ", err);
        }
    };

    useEffect(() => {
        if (specializationDoc != "") {
            DoctorSpecialization(specializationDoc);
        }
    }, [specializationDoc]);

// Nav specialization doctor
    const navSpecializtion = async () => {
        try {
            const resp = await axios.get(`${baseURL}/sec/patient/doctorDeptartments`);
            // console.log(resp?.data?.response);
            setNav_spelization(resp?.data?.response);
        } catch (err) {
            console.log("Nav specialization error : ", err);
        }
    };

    useEffect(() => {
        navSpecializtion();
    }, []);

    return (
        <>
            <nav className="NavBar-Container-Appoinement">
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/about`}>About</NavLink>
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/department`}>
                    Department
                </NavLink>
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/labs`}>Labs</NavLink>
            </nav>
            <div className="about-data" style={{ marginTop: "4rem", width : "100%" }}>
                {/* {departData.map((data, index) => ( */}
                <div key={`{data?.suid}{index}`}>
                    {/* Conditionally render Container3 or Container5 */}
                    {/* {index % 2 === 0 ? ( */}
                    {/* <Container5 lab_id={"data.lab_id"} lab_names={""} /> */}

                    {/* ) : ( */}

                                    {/* Category component starts */}
                <Box sx={{width : "100%"}} >
                    <Box sx={{ display: "flex", alignItems: "center" , width : "100%" }}>
                        <div onClick={handleScrollLeft}>
                            {" "}
                            {/* Add left arrow */}
                            <ChevronLeftIcon />
                        </div>
                        <Box
                            sx={{ display: "flex", position: "relative" }} // Add relative positioning
                            className={"horizontal-scroll-container NavBar-Container-one"}
                        >
                            <div
                                ref={scrollContainerRef}
                                style={{ overflowX: "auto", display: "flex" }}
                            >
                            
                                {nav_specialization.map((specialization, index) => (
                                    <CustomButton
                                        key={index}
                                        to={`/patientdashboard/${specialization?.department_name.toLowerCase()}`}
                                        label={`${specialization?.department_name}`}
                                        isTransaprent={
                                            specialization.department_name.toLowerCase() ===
                                            specializationDoc.toLowerCase()
                                                ? false
                                                : true
                                        }
                                        buttonCss={{
                                            borderRadius: "50px",
                                            padding: "0 6.5%",
                                            marginRight: "1%",
                                            whiteSpace: "normal",
                                        }}
                                        handleClick={() => {
                                            setSpecializationDoc(specialization?.department_name);
                                        }}
                                    >
                                    </CustomButton>
                                ))}
                                {/* <NavLink to={"/more"}>More...</NavLink> */}
                            </div>
                        </Box>
                        <div onClick={handleScrollRight}>
                            {" "}
                            {/* Add right arrow */}
                            <ChevronRightIcon />
                        </div>
                    </Box>
                    <CallCardData
                        linkPath={`/patientdashboard/drdetailscard/`}
                        sendCardData={
                            specializationCardData === "" ? specializationCardData : specializationCardData
                        }
                        // CardData={data}
                        textField={""}
                    />
                </Box>

                    {/* <Container3
                        test_id={"data.test_id"}
                        about={"data.about"}
                        amount={"data.amount"}
                        service_day_from={"data.service_day_from"}
                        service_day_to={"data.service_day_to"}
                    /> */}
                    {/* )} */}
                </div>
                {/* ))} */}
            </div>
        </>
    );
};

export default Department;
