import { Box, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../../../components/CustomButton/custom-button";
import CustomModal from "../../../../../components/CustomModal/custom-modal";
// import SingleLineGridList from "./Crousal";
import personIcon from "../../../../../constants/DrImages/icon.svg";
import messageIcon from "../../../../../constants/DrImages/message.svg";
import bagIcon from "../../../../../constants/DrImages/bag.svg";
import HCFStepper from ".././HCFStepper";
import starIcon from "../../../../../constants/DrImages/Group 92.svg";

const HCFDrCard = ({
    data,
    test_id = "",
    about = "",
    amount = "",
    service_day_from = "",
    service_day_to = "",
}) => {
    const [openDialog, setOpenDialog] = useState(false);

    console.log("Data card : ", data);

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                marginLeft: "1%",
                border: "1px solid #E6E1E5",
                padding: "2%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                }}
            >
                {data?.sub_exam_name}
                <Box sx={{ color: "gray", fontWeight: "light" }}>Price: {data?.test_price}</Box>
            </Box>
            <Box sx={{ display: "flex" }}>
                <Typography
                    sx={{
                        textAlign: "left",
                        fontFamily: "Poppins",
                        fontSize: "1em",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "21px" /* 150% */,
                        color: "#939094",
                        marginTop: "1%",
                    }}
                >
                    Working days :{data?.lab_working_days_from} to {data?.lab_working_days_from},
                    <Typography sx={{ display: "flex", marginTop: "2%" }}>
                        Working Time : {data?.lab_working_time_from} to {data?.lab_working_time_to}
                        {/* Description : {about}{" "} */}
                    </Typography>
                </Typography>
                {/* <CustomButton label="Buy"   buttonCss={{marginLeft:"150px",width:"50px",height:"40px",marginTop:"50px"}}></CustomButton> */}
                <CustomButton
                    label={"Buy"}
                    isElevated
                    buttonCss={{ height: "40px", display: "block", margin: "auto" }}
                    handleClick={() => setOpenDialog(!openDialog)}
                />
                <CustomModal
                    isOpen={openDialog}
                    title={"dialog title"}
                    footer={<Fragment></Fragment>}
                    disableBackdropClick={true} // or false based on your needs
                    conditionOpen={setOpenDialog} // Ensure this is passed to handle modal close
                >
                    <div>
                        <HCFStepper data={data} />
                    </div>
                </CustomModal>
            </Box>
        </Box>
    );
};

export default HCFDrCard;
