/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, IconButton } from "@mui/material";

const CustomTextField = ({
    id,
    label,
    defaultValue,
    helperText,
    variant,
    isValid,
    isInvalid,
    isDisabled,
    leftIcon,
    rightIcon,
    onChange,
    textcss,
    inputType,
    CustomValue,
    placeholder,
    onInput,
    type,
    multiline,
    rows,
    maxRows,
    onLeftIconClick,  // New prop for handling left icon click
    onRightIconClick, // New prop for handling right icon click
}) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
        <TextField
            type={type}
            className={`${isValid ? "valid-class" : ""}${isInvalid ? "invalid-class" : ""}`}
            typeof={type}
            id={id}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            label={label}
            placeholder={placeholder}
            value={CustomValue}
            defaultValue={defaultValue}
            helperText={helperText}
            variant={variant}
            disabled={isDisabled}
            sx={textcss}
            onInput={onInput}
            onChange={onChange}
            InputLabelProps={{
                shrink: !!defaultValue || !!isFocused,
                style: leftIcon ? { paddingLeft: 30 } : {},
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...(leftIcon || rightIcon
                ? {
                      // only add InputProps if leftIcon or rightIcon is defined
                      InputProps: {
                          startAdornment: leftIcon ? (
                              <InputAdornment position="start">
                                  <IconButton onClick={onLeftIconClick}>
                                      {leftIcon}
                                  </IconButton>
                              </InputAdornment>
                          ) : null,
                          endAdornment: rightIcon ? (
                              <InputAdornment position="end">
                                  <IconButton onClick={onRightIconClick}>
                                      {rightIcon}
                                  </IconButton>
                              </InputAdornment>
                          ) : null,
                      },
                  }
                : {})}
        />
    );
};

CustomTextField.defaultProps = {
    id: "text-field",
    label: "username",
    defaultValue: "",
    helperText: "valid username",
    variant: "standard",
    isValid: false,
    isInvalid: false,
    isDisabled: false,
    leftIcon: null,  // Allow custom icon to be passed
    rightIcon: null,  // Allow custom icon to be passed
    onChange: () => {},
    onLeftIconClick: () => {},  // Default no-op for icon click
    onRightIconClick: () => {}, // Default no-op for icon click
};

CustomTextField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    helperText: PropTypes.string,
    variant: PropTypes.string,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    isDisabled: PropTypes.bool,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    onChange: PropTypes.func,
    onInput: PropTypes.string.isRequired,
    onLeftIconClick: PropTypes.func,  // Prop type for left icon click handler
    onRightIconClick: PropTypes.func, // Prop type for right icon click handler
};

export default CustomTextField;


































// /* eslint-disable react/prop-types */
// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { TextField, InputAdornment } from "@mui/material";

// const CustomTextField = ({
//     id,
//     label,
//     defaultValue,
//     helperText,
//     variant,
//     isValid,
//     isInvalid,
//     isDisabled,
//     leftIcon,
//     rightIcon,
//     onChange,
//     textcss,
//     inputType,
//     CustomValue,
//     placeholder,
//     onInput,
//     type,
//     multiline,
//     rows,
//     maxRows,
// }) => {
//     const [isFocused, setIsFocused] = useState(false);

//     return (
//         <TextField
//             type={type}
//             className={`${isValid ? "valid-class" : ""}${isInvalid ? "invalid-class" : ""}`}
//             typeof={type}
//             id={id}
//             multiline={multiline}
//             rows={rows}
//             maxRows={maxRows}
//             label={label}
//             placeholder={placeholder}
//             value={CustomValue}
//             defaultValue={defaultValue}
//             helperText={helperText}
//             variant={variant}
//             disabled={isDisabled}
//             sx={textcss}
//             onInput={onInput}
//             onChange={onChange}
//             InputLabelProps={{
//                 shrink: !!defaultValue || !!isFocused,
//                 style: leftIcon ? { paddingLeft: 30 } : {},
//             }}
//             onFocus={() => setIsFocused(true)}
//             onBlur={() => setIsFocused(false)}
//             {...(leftIcon || rightIcon
//                 ? {
//                       // only add InputProps if leftIcon or rightIcon is defined
//                       InputProps: {
//                           startAdornment: leftIcon ? (
//                               <InputAdornment position="start">{leftIcon}</InputAdornment>
//                           ) : null,
//                           endAdornment: rightIcon ? (
//                               <InputAdornment position="end">{rightIcon}</InputAdornment>
//                           ) : null,
//                       },
//                   }
//                 : {})}
//         />
//     );
// };

// CustomTextField.defaultProps = {
//     id: "text-field",
//     label: "username",
//     defaultValue: "",
//     helperText: "valid username",
//     variant: "standard",
//     isValid: false,
//     isInvalid: false,
//     isDisabled: false,
//     leftIcon: null,  // Allow custom icon to be passed
//     // leftIcon: "",
//     rightIcon: "",
//     onChange: () => {},
// };

// CustomTextField.propTypes = {
//     id: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     defaultValue: PropTypes.string,
//     helperText: PropTypes.string,
//     variant: PropTypes.string,
//     isValid: PropTypes.bool,
//     isInvalid: PropTypes.bool,
//     isDisabled: PropTypes.bool,
//     leftIcon: PropTypes.node,
//     rightIcon: PropTypes.node,
//     onChange: PropTypes.func,
//     onInput: PropTypes.string.isRequired,
// };

// export default CustomTextField;
