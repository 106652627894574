import React, { useEffect, useState } from "react";
// import DoctorStatisticsNavbar from "../../CustomDoctorComponent/DoctorStatisticsNavbar/DoctorStatisticsNavbar";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { PaginationCard } from "../../../Dashboard/PatientAppointment/PatientCards";
import { DoctorBookingCard } from "../../../DoctorModule/DoctorStatistics/DoctorBookingHistory/DoctorBookingCard";
import CustomButton from "../../../components/CustomButton";
import { baseURL } from "../../../constants/const";
import DateModal from "../../../components/DateModal/DateModal";
import FilterModal from "../../../components/FilterModal/FilterModal";
import axiosInstance from "../../../config/axiosInstance";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData(
        <DoctorBookingCard name={"Jolie"} patientId={"0001"} Id={"001"} />,
        <CustomButton label="Complete" isTransaprent />,
        <Typography
            sx={{
                color: "#939094",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "0.625rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "0.9375rem",
                letterSpacing: "0.005rem",
            }}
        >
            01-02-2023 10:30 AM
        </Typography>,
        <Typography
            sx={{
                color: "#939094",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "0.625rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "0.9375rem",
                letterSpacing: "0.005rem",
            }}
        >
            30min | Messaging pack
        </Typography>,
        <h5 style={{ color: "red" }}>₹12</h5>,
    ),
    createData(
        <DoctorBookingCard name={"Jolie"} patientId={"0001"} Id={"002"} />,
        <CustomButton label="Cancelled" isTransaprent />,
        <Typography
            sx={{
                color: "#939094",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "0.625rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "0.9375rem",
                letterSpacing: "0.005rem",
            }}
        >
            01-02-2023 10:30 AM
        </Typography>,
        <Typography
            sx={{
                color: "#939094",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "0.625rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "0.9375rem",
                letterSpacing: "0.005rem",
            }}
        >
            30min | Video call pack
        </Typography>,
        <h5 style={{ color: "red" }}>$20</h5>,
    ),
];

const SuperAdminLogs = () => {
    const [cardData, setCardData] = useState();
    const fetchData = async () => {
        try{
            const resp = await axiosInstance.get('/sec/superadmin/auditLogs');
            console.log("Dashboard Response from API: ",resp?.data);
            setCardData(resp?.data?.response);
        }catch(err){
            console.log("Error : ", err)
        }
      }
      
      useEffect( () => {
        fetchData();
      },[] )
    React.useEffect(() => {
        localStorage.setItem("activeComponent", "logs");
        localStorage.setItem("path", "auditlogs");
    }, []);
    


    
    return (
        <>
            <div className="nav-d-f-container">
                <nav className="NavBar-Container-Appoinement">
                    <NavLink to={"/superadmin/logs/auditlogs"}>Audit Logs</NavLink>
                </nav>
                <div className="date-filter1">
                    <DateModal />
                    <FilterModal />
                </div>
            </div>
            <Box sx={{ width: "100%", height: "100%" }}>
                <div className="">
                    <TableContainer component={Paper} style={{ background: "white" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name & Details</TableCell>
                                    <TableCell align="right">Status</TableCell>
                                    <TableCell align="right">Action ID</TableCell>
                                    <TableCell align="right">Action</TableCell>
                                    <TableCell align="right">Timestamp</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {cardData?.map((data) => (
                                        <TableRow
                                            key={data?.event_id}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell component="th" scope="row" align="right">
                                                {data?.event_name}
                                            </TableCell>
                                            <TableCell align="right">{data?.access_role}</TableCell>
                                            <TableCell align="right">{data?.event_id}</TableCell>
                                            <TableCell align="right">{data?.event_source}</TableCell>
                                            <TableCell align="right">{data?.time_spent}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                        </Table>
                    </TableContainer>
                    <div style={{ marginTop: "300px" }}>
                        <PaginationCard />
                    </div>
                </div>
            </Box>
        </>
    );
};

export default SuperAdminLogs;
