import { Box, Button, Container, Typography } from "@mui/material";
import * as React from "react";
import { NavLink } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import DoctorImage from "../../../constants/DrImages/image1.png";
import "react-datepicker/dist/react-datepicker.css";
// import { BookingHistoryDrCard } from "./BookingHistoryDrCard";
import CustomButton from "../../../components/CustomButton/custom-button";
import { ReceiveCard, SendCard } from "./TransactionCard";
import axiosInstance from "../../../config/axiosInstance";
import NoAppointmentCard from "../../PatientAppointment/NoAppointmentCard/NoAppointmentCard";
import { PaginationCard } from "../../PatientAppointment/PatientCards";
import "./transaction.scss"
const Transactions = () => {
    React.useEffect(() => {
        localStorage.setItem("activeComponent", "manage");
        localStorage.setItem("path", "transactions");
    }, []);

    const [patientID, setPatientID] = React.useState(localStorage.getItem("patient_suid"));
    const [data, setData] = React.useState([]);

    const fetchData = async () => {
        try {
            const resp = await axiosInstance.post(
                "/sec/patient/Transaction",
                JSON.stringify({
                    patient_id: patientID,
                    role_id: 5,
                }),
            );
            setData(resp?.data?.response);
            console.log("response : ", resp?.data?.response);
        } catch (err) {
            console.log("Error : ", err);
        }
    };

    React.useEffect(() => {
        setPatientID(localStorage.getItem("patient_suid"));
        fetchData();
    }, []);

    if (!data) return null;

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                <nav className="NavBar-Container-Appoinement">
                    <NavLink to={"/patientdashboard/manage/bookinghistory"}>
                        Booking History
                    </NavLink>
                    <NavLink to={"/patientdashboard/manage/transactions"}>Transaction</NavLink>
                    <NavLink to={"/patientdashboard/manage/reports"}>Report</NavLink>
                    {/* <NavLink to={"/patientdashboard/manage/subscriptions"}>Subscription</NavLink> */}
                </nav>

                <Box className="allfile-main-container">
                    <Box
                        component={"div"}
                        sx={{
                            position: "relative",
                            top: "4em",
                            width: "100%",
                            display: "flex",
                            height: "90%",
                        }}
                    >
                        <TableContainer component={Paper} sx={{ backgroundColor: "#ffff" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Transaction & ID</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">Date & Time</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.length === 0 ? (
                                        <NoAppointmentCard
                                            text_one={"You don't have any transaction"}
                                        />
                                    ) : (
                                        data.map((row) => (
                                            <TableRow
                                                key={row.TransactionID}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row?.status === "created" ? (
                                                        <ReceiveCard
                                                            Payment={"Cancellation Refund"}
                                                            TRXID={row?.braintree_trans_id}
                                                        />
                                                    ) : (
                                                        <SendCard
                                                            Payment={"Appointment Payment"}
                                                            TRXID={row?.braintree_trans_id}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">{row.xyz}</TableCell>
                                                <TableCell align="right">{row.abc}</TableCell>
                                                <TableCell align="center">
                                                    {row?.updated_at.split("T")[0]} |{" "}
                                                    {row?.updated_at.split("T")[0].split(".")[0]}
                                                </TableCell>
                                                <TableCell align="center">{row?.amount}</TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box>
                        <PaginationCard/>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Transactions;
