import React from "react";

const DiagnostCenterTableCard = ({name , id}) => {
    return(
        <div style={{display : "flex" , width :"100%" , height : "10%" , borderRadius : "10px"}}>
                                       <div style={{width : "5em" , height : "5em"}} >
                                        <img width={"100%"} height={"100%"} style={{width : "100%" , height : "100%" , borderRadius : "7px"}} src="https://images.unsplash.com/photo-1529946179074-87642f6204d7?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="image not found" />
                                       </div>
                                        <div style={{width : "65%" , height : "100%", marginLeft : "2%", display : "flex" , alignItems : "center" }} >
                                            <div>
                                                {name}
                                            </div> 
                                            <div>
                                                {id}
                                            </div>
                                        </div>
                                    </div>
    )
}

export default DiagnostCenterTableCard;