import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { PatientSearchTable } from "../../../../HCFModule/DiagnosticCenter/DiagnosticCenterReports/DiagnosticPatientSearch/PatientSearchTable";
import { PaginationCard } from "../../../PatientAppointment/PatientCards";
import axiosInstance from "../../../../config/axiosInstance";
import "./examined.scss";

function createData(name, calories, fat, carbs, protein, action, plan) {
    return { name, calories, fat, carbs, protein, action, plan };
}
const rows = [
    createData(
        <PatientSearchTable name={"Apollo Diag"} Id={"TST00012"} />,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <Typography>$100</Typography>,
        <div>
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),
    createData(
        <PatientSearchTable name={"Apollo Diag"} Id={"TST00012"} />,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <Typography>$100</Typography>,
        <div>
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),

    createData(
        <PatientSearchTable name={"Apollo Diag"} Id={"TST00012"} />,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <Typography>$100</Typography>,
        <div>
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),
];
const Examined = () => {
    const [value, setValue] = useState([null, null]);
    const [tableData, setTableData] = useState([]);
    const [patientID, setPatientID] = React.useState(localStorage.getItem("patient_suid"));
    function getWeeksAfter(date, amount) {
        return date ? date.add(amount, "week") : undefined;
    }

    const FetchData = async () => {
        try {
            const response = await axiosInstance.post(
                "/sec/patient/ReportsExamine",
                JSON.stringify({
                    patient_id: patientID,
                    status: "examine",
                }),
            );
            console.log(response?.data?.response);
            setTableData(response?.data?.response);
        } catch (error) {
            console.log("Error : ", error);
        }
    };

    useEffect(() => {
        FetchData();
    }, []);

    return (
        <>
            {/* <Box
                sx={{
                    position: "absolute",
                    width: "40%",
                    left: "58%",
                    top: "-20%",
                }}
            > */}
            {/* Date Range picker */}
            {/* <Box sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            disablePast
                            value={value}
                            maxDate={getWeeksAfter(value[0], 4)}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </Box> */}
            {/* </Box> */}
            <Box className="allfile-main-container">
                <Box>
                    <TableContainer component={Paper} style={{ background: "white" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Lab Name/Booking ID</TableCell>
                                    <TableCell align="right">Date & time</TableCell>
                                    <TableCell align="right">Schedule</TableCell>
                                    <TableCell align="right">Test Name</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    {/* <TableCell align="right">Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Add pagination to get the proper response  */}
                                {tableData.map((row, index) => {
                                    if (index < 5) {
                                        return (
                                            <TableRow
                                                key={row.name}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <PatientSearchTable
                                                        name={row?.labName}
                                                        Id={row?.labID}
                                                    />{" "}
                                                </TableCell>
                                                <TableCell align="right">{`${
                                                    row?.book_date.split("T")[0]
                                                } | ${row?.book_time}`}</TableCell>
                                                <TableCell align="right">{`${
                                                    row?.Scheduled.split("T")[0]
                                                } | ${row?.Scheduled.split("T")[1]} `}</TableCell>
                                                <TableCell align="right">
                                                    {row?.test_name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row?.test_price}
                                                </TableCell>
                                                {/* <TableCell align="right">{row.action}</TableCell>
                                    <TableCell align="right">{row.plan}</TableCell> */}
                                            </TableRow>
                                        );
                                    } else {
                                        return;
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box>
                    <PaginationCard />
                </Box>
            </Box>
        </>
    );
};
export default Examined;
