import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, json } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import axios from "axios";
import CustomButton from "../../../components/CustomButton/custom-button";
import { BookingHistoryDrCard } from "./BookingHistoryDrCard";
import DoctorImage from "../../../constants/DrImages/image1.png";
import { baseURL, currencysign } from "../../../constants/const";
import axiosInstance from "../../../config/axiosInstance";
import NoAppointmentCard from "../../PatientAppointment/NoAppointmentCard/NoAppointmentCard";
import { PaginationCard } from "../../PatientAppointment/PatientCards";

// console.log("Calling Bokking history")

const BookingHistory = () => {
    const [bookingHistoryData, setBookingHistoryData] = useState([]);
    const [patiendId, setPatientID] = useState(localStorage.getItem("patient_suid"));

    // function createData(name, calories, fat, carbs, protein) {
    //     return { name, calories, fat, carbs, protein };
    // }

    function getWeeksAfter(date, amount) {
        return date ? date.add(amount, "week") : undefined;
    }
    //     const [startDate, setStartDate] = React.useState(null);
    //   const [endDate, setEndDate] = React.useState(null);

    const fetchData = async () => {
        try {
            const response = await axiosInstance(`/sec/patient/appointmentHistory/${patiendId}`);
            // const response = await axios.post(`${baseURL}/sec/patient/appointmentHistory`, JSON.stringify());
            setBookingHistoryData(response?.data?.response);
            console.log("Booking history data :", response?.data?.response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        localStorage.setItem("activeComponent", "manage");
        localStorage.setItem("path", "bookinghistory");
        setPatientID(localStorage.getItem("patient_suid"));
        fetchData();
    }, []);

    const [value, setValue] = React.useState([null, null]);
    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
                <nav className="NavBar-Container-Appoinement">
                    <NavLink to={"/patientdashboard/manage/bookinghistory"}>
                        Booking History
                    </NavLink>
                    <NavLink to={"/patientdashboard/manage/transactions"}>Transaction</NavLink>
                    <NavLink to={"/patientdashboard/manage/reports"}>Report</NavLink>
                    {/* <NavLink to={"/patientdashboard/manage/subscriptions"}>Subscription</NavLink> */}
                </nav>
                {/* <Box
                    sx={{
                        position: "relative",
                        top: "4em",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                > */}
                {/* <Box sx={{ width: "40%" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                disablePast
                                value={value}
                                maxDate={getWeeksAfter(value[0], 4)}
                                onChange={(newValue) => {
                                    setValue(newValue);
                                }}
                                renderInput={(startProps, endProps) => (
                                    <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }}> to </Box>
                                        <TextField {...endProps} />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
                    </Box> */}
                {/* </Box> */}
                <Box className="allfile-main-container">
                    <Box
                        component={"div"}
                        sx={{
                            position: "relative",
                            top: "4.5em",
                            width: "100%",
                            display: "flex",
                            height: "90%",
                        }}
                    >
                        <TableContainer component={Paper} sx={{ backgroundColor: "#ffff" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name & Details</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Date & Time</TableCell>
                                        <TableCell align="center">Package</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bookingHistoryData?.length === 0 ? (
                                        <NoAppointmentCard
                                            text_one={"There is no Booking history"}
                                        />
                                    ) : (
                                        bookingHistoryData.map((row) => (
                                            <TableRow
                                                key={row?.appointment_id}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <BookingHistoryDrCard
                                                        name={`${row?.first_name} ${row?.middle_name} ${row?.last_name}`}
                                                        specialist={"Neurologist"}
                                                        BookingId={"001"}
                                                        profileImage={`${row?.profile_picture}`}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CustomButton
                                                        buttonCss={{
                                                            display: "inline-flex",
                                                            height: "2rem",
                                                            padding: "0.5rem 1rem",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            gap: "0.5rem",
                                                            flexShrink: "0",
                                                            borderRadius: "6.25rem",
                                                        }}
                                                        isTransaprent={"true"}
                                                        label={`${row?.status}`}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    {" "}
                                                    <Typography
                                                        sx={{
                                                            color: "#939094",
                                                            textAlign: "center",
                                                            fontFamily: "Poppins",
                                                            fontSize: "0.625rem",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            lineHeight: "0.9375rem",
                                                            letterSpacing: "0.005rem",
                                                        }}
                                                    >
                                                        {row?.appointment_date.split("T")[0]} |{" "}
                                                        {row?.appointment_time}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography
                                                        sx={{
                                                            color: "#939094",
                                                            textAlign: "center",
                                                            fontFamily: "Poppins",
                                                            fontSize: "0.800rem",
                                                            fontStyle: "normal",
                                                            fontWeight: "400",
                                                            lineHeight: "0.9375rem",
                                                            letterSpacing: "0.005rem",
                                                        }}
                                                    >
                                                        {row?.plan_duration} | {row?.plan_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Typography
                                                        sx={{
                                                            color: "#E72B4A",
                                                            textAlign: "center",
                                                            fontFamily: "Poppins",
                                                            fontSize: "0.875rem",
                                                            fontStyle: "normal",
                                                            fontWeight: "500",
                                                            lineHeight: "1.375rem",
                                                            letterSpacing: "0.00438rem",
                                                        }}
                                                    >
                                                        {currencysign} {row?.plan_fee}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                    {/* {rows.map((row) => (
                                <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.calories}</TableCell>
                                <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell>
                                </TableRow>
                            ))} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box>
                        <PaginationCard />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BookingHistory;
