import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Box, Paper } from "@material-ui/core";
import Dept from "../../../constants/DrImages/Out Patient Department.png";
import Name from "../../../constants/DrImages/Name.png";
import Examine from "../../../constants/DrImages/Examination.png";
import DashboardTable from "./DashboardTable";
import axios from "axios"
import axiosInstance from "../../../config/axiosInstance"
import { useNavigate } from "react-router-dom";
import { data } from "../../../constants/const";


const AdminDashboard = () => {
    const [cardData, setCardData] = useState();
    const [error, setError] = useState(null);
    const [patientCount, setPatientCount] = useState();
    const [doctorCount, setDoctorCount] = useState();
    const [healthcareFacilityCount, setHealthcareFacilityCount] = useState();

    const fetchData = async () => {
        try{
            const resp = await axiosInstance.get('/sec/superadmin/DashboardCount');
            console.log("Dashboard DAta reject cd: ",resp?.data);
            setCardData(resp?.data);
            setDoctorCount(resp?.data.DOCTORS);
            setPatientCount(resp?.data.PATIENT);
            setHealthcareFacilityCount(resp?.data.HCF);

        }catch (err) {
            console.error("Error fetching dashboard data: ", err);
            setError(err.message || "Failed to fetch dashboard data");
          }
      }
      
      useEffect( () => {
        fetchData();
      },[] )      
    return (
        <>
            <div style={{ display: "flex" }}>
                <div className="DoctorDashboardCard">
                    <div className="Number-Container">
                        <Typography
                            sx={{
                                color: "#E72B4A",
                                fontFamily: "Poppins",
                                fontSize: "3rem",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "4.625rem",
                                marginTop: "30px",
                                marginLeft: "20px",
                            }}
                        >
                            {doctorCount}
                        </Typography>
                    </div>
                    <div className="Number-Container">
                        <Typography
                            sx={{
                                color: "#313033",
                                fontFamily: "Poppins",
                                fontSize: "1.5rem",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "1.5rem",
                                marginLeft: "10px",
                                marginTop: "10px",
                            }}
                        >
                            Doctor
                        </Typography>
                        <Box
                            component={"img"}
                            sx={{
                                width: "100px",
                                height: "100px",
                                marginLeft: "150px",
                                marginTop: "-90px",
                            }}
                            src={Dept}
                            alt="Our Patient Department"
                        ></Box>
                    </div>
                </div>

                <div className="DoctorDashboardCard" style={{ marginLeft: "20px" }}>
                    <div className="Number-Container">
                        <Typography
                            sx={{
                                color: "#E72B4A",
                                fontFamily: "Poppins",
                                fontSize: "3rem",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "4.625rem",
                                marginTop: "30px",
                                marginLeft: "20px",
                            }}
                        >
                           {patientCount}
                        </Typography>
                    </div>
                    <div className="Number-Container">
                        <Typography
                            sx={{
                                color: "#313033",
                                fontFamily: "Poppins",
                                fontSize: "1.5rem",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "1.5rem",
                                marginLeft: "10px",
                                marginTop: "10px",
                            }}
                        >
                            Patient
                        </Typography>
                        <Box
                            component={"img"}
                            sx={{
                                width: "100px",
                                height: "100px",
                                marginLeft: "150px",
                                marginTop: "-90px",
                            }}
                            src={Name}
                            alt="Name"
                        ></Box>
                    </div>
                </div>

                <div className="DoctorDashboardCard" style={{ marginLeft: "20px" }}>
                    <div className="Number-Container">
                        <Typography
                            sx={{
                                color: "#E72B4A",
                                fontFamily: "Poppins",
                                fontSize: "3rem",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "4.625rem",
                                marginTop: "30px",
                                marginLeft: "20px",
                            }}
                        >
                            {healthcareFacilityCount}
                        </Typography>
                    </div>
                    <div className="Number-Container">
                        <Typography
                            sx={{
                                color: "#313033",
                                fontFamily: "Poppins",
                                fontSize: "1.5rem",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "1.5rem",
                                marginLeft: "10px",
                                marginTop: "10px",
                            }}
                        >
                            HCF
                        </Typography>
                        <Box
                            component={"img"}
                            sx={{
                                width: "100px",
                                height: "100px",
                                marginLeft: "150px",
                                marginTop: "-90px",
                            }}
                            src={Examine}
                            alt="Examination"
                        ></Box>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminDashboard;
