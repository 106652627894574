import React, { useState } from "react";
import { Box, Card, CardContent, CardMedia, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./searchbarmodalcard.scss";
import Imagestar from "../DrImages/ShiningStar.png"
// import { Box, Card } from "@mui/material";


const SearchBarModalCard = ({ DrData }) => {
    console.log(DrData)
    // const classes = useStyles();
    const [
        name = "",
        middle_name = "",
        last_name = "",
        id,
        qualification,
        specialist,
        rating,
        hospital,
        // reviews,
        // image,
        // hospital,
        // specialist,
        // rating ,
        // reviews
    ] = [
        DrData.first_name,
        DrData.middle_name,
        DrData.last_name,
        DrData.suid,
        DrData.qualification,
        DrData.department_name,
        DrData.review_type,
        DrData.hospital_org,
        // DrData.drimage ,
        // DrData.hospital ,
        // DrData.specialist,
        // DrData.rating,
        // DrData.reviews
    ];

    return (
        <Box key={id} sx={{ width: "100%", margin: "2px" ,height : "100%" , display : "flex" , borderRadius : "12px" ,   boxShadow : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)" }}>
            <div className="searchbar-modal-image">
                <img src="https://images.unsplash.com/photo-1550831107-1553da8c8464?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" style={{width : "100%" , height : "100%" , borderRadius : "8px" }} ></img>
            </div>
            
            <div className="search-card-text-container">
                <span>
                    <p>{`${!name ? "" : name} ${!middle_name ? "" : middle_name} ${!last_name ? "" : last_name}`}</p>
                </span>
                <span>
                    <p>Hospital Name | Qulification</p>
                </span>
                <span>
                    <p>specialist</p>
                </span>
                <span>
                    <p>Review | Rating</p>
                </span>
            </div>
        </Box>
    );
};

export default SearchBarModalCard;