// import React from "react";
// import { Box, Typography } from "@mui/material";
// import { Link } from "react-router-dom";
// import CustomButton from "../../../../components/CustomButton/custom-button";
// // import CustomButton from "../../../components/CustomButton/custom-button";

// export const AllFiles = () => {
//     return (
//         <Box sx={{ width: "100%", display: "flex" }}>
//             <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>

//             </Box>
//         </Box>
//     );
// };

import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { PatientSearchTable } from "../../../../HCFModule/DiagnosticCenter/DiagnosticCenterReports/DiagnosticPatientSearch/PatientSearchTable";
import { PaginationCard } from "../../../PatientAppointment/PatientCards";
import axiosInstance from "../../../../config/axiosInstance";
import "./allfiles.scss";

const AllFiles = () => {
    const [value, setValue] = useState([null, null]);
    const [tableData, setTableData] = useState([]);
    const [patientID, setPatientID] = React.useState(localStorage.getItem("patient_suid"));
    function getWeeksAfter(date, amount) {
        return date ? date.add(amount, "week") : undefined;
    }

    const FetchData = async () => {
        try {
            const response = await axiosInstance.post(
                "/sec/patient/ReportsRequested/",
                JSON.stringify({
                    patient_id: patientID,
                    status: "requested",
                }),
            );
            console.log(response?.data?.response);
            setTableData(response?.data?.response);
        } catch (error) {
            console.log("Error : ", error);
        }
    };

    useEffect(() => {
        FetchData();
    }, []);

    return (
        <>
            {/* <Box
                sx={{
                    // position: "absolute",
                    // width: "40%",
                    // left: "58%",
                    // top: "-20%",
                }}
                className="allfile-main-container"
            > */}
            {/* This is the date range picker */}
            {/* <Box sx={{ width: "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                            disablePast
                            value={value}
                            maxDate={getWeeksAfter(value[0], 4)}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </Box> */}
            {/* </Box> */}
            <Box className="allfile-main-container">
                <Box>
                    <TableContainer component={Paper} style={{ background: "white" }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Lab Name/Booking ID</TableCell>
                                    <TableCell align="right">Date & time</TableCell>
                                    <TableCell align="right">Schedule</TableCell>
                                    <TableCell align="right">Test Name</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    {/* <TableCell align="right">Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Add pagination to get the proper response  */}
                                {tableData.map((row, index) => {
                                    if (index < 5) {
                                        return (
                                            <TableRow
                                                key={row.name}
                                                sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <PatientSearchTable
                                                        name={row?.labName}
                                                        Id={row?.labID}
                                                    />{" "}
                                                </TableCell>
                                                <TableCell align="right">{`${
                                                    row?.book_date?.split("T")[0]
                                                } | ${row?.book_time}`}</TableCell>
                                                <TableCell align="right">{`${
                                                    row?.Scheduled?.split("T")[0]
                                                } | ${row?.Scheduled?.split("T")[1]} `}</TableCell>
                                                <TableCell align="right">
                                                    {row?.test_name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row?.test_price}
                                                </TableCell>
                                                {/* <TableCell align="right">{row.action}</TableCell>
                                    <TableCell align="right">{row.plan}</TableCell> */}
                                            </TableRow>
                                        );
                                    } else {
                                        return;
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box>
                    <PaginationCard />
                </Box>
            </Box>
        </>
    );
};
export default AllFiles;
