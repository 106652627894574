import React from "react";
import { NavLink, useParams } from "react-router-dom";
import Container5 from "../Container5";
import Container3 from "../Container3";
import ContainerThree from "../../../../../Dashboard/DrDetailsCard/ContainerThree";

const About = () => {
    const ID = useParams();
    console.log("Params abour : " , ID.hcfID)
    return (
        <>
            <nav className="NavBar-Container-Appoinement">
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/about`}>About</NavLink>
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/department`}>
                    Department
                </NavLink>
                <NavLink to={`/patientdashboard/hcfDetailCard/${ID.hcfID}/labs`}>Labs</NavLink>
            </nav>
            <div className="about-data" style={{ marginTop: "4rem" }}>
                {/* <Container5 /> */}
                <ContainerThree/>
                {/* <Container3 /> */}
            </div>
        </>
    );
};

export default About;
