import React from "react";
import "./Howitworks.scss";
import { Box, Typography } from "@mui/material";
import ImageLogo from "../../../static/images/logo.png";
import Alldoctor from "../HowitworksImages/Alldoctors.png";
import Whitelogo from "../HowitworksImages/whiteFrame.png";
import { LinkedIn } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Facebook } from "@mui/icons-material";
import CustomButton from "../../../components/CustomButton";
import ScrewDoctor from "../HowitworksImages/MonitorDoc.png";
import { Link, useNavigate } from "react-router-dom";

function Howitworks() {
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/selectprofiletype");
    };
    return (
        <>
            <div className="screen-container">
                <div className="header-container1">
                    <Link to={"/"}>
                        <Box component={"img"} src={ImageLogo}></Box>
                    </Link>
                    <div className="other-header-content">
                        <Typography
                            style={{
                                color: "#373737",
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "36px",
                            }}
                        >
                            How it works?
                        </Typography>
                        <Link to={"/aboutlanding"} style={{ textDecoration: "none" }}>
                            <Typography
                                style={{
                                    color: "#373737",
                                    fontFamily: "Poppins",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "36px",
                                }}
                            >
                                About Us
                            </Typography>
                        </Link>
                        <Typography
                            style={{
                                color: "#373737",
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "36px",
                            }}
                        >
                            Pricing
                        </Typography>
                        <CustomButton
                            label="Sign Up"
                            buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                            isTransaprent={"True"}
                            handleClick={() => {
                                navigate("/selectsignup");
                            }}
                        ></CustomButton>
                        <CustomButton
                            label="Login"
                            buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                            handleClick={handleSubmit}
                        ></CustomButton>
                    </div>
                </div>
                <div className="howitworks-heading">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "Playfair Display",
                            fontSize: "64px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "94px",
                        }}
                    >
                        HOW it Works
                    </Typography>
                </div>
                <div className="description">
                    <Typography
                        className="des-text"
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        we pay close attention to the user experience on our medical company
                        website. User-friendly navigation is key, ensuring that visitors can easily
                        find the information they seek. We optimize our content for search engines,
                        making it discoverable to those searching for medical insights. Regular
                        updates and revisions are part of our commitment to keeping the information
                        current, and we actively engage with our audience to gather feedback and
                        answer their questions. By maintaining a dynamic, informative, and
                        user-centric approach to content creation, our medical company website aims
                        to be a valuable resource for all those seeking reliable medical knowledge
                        and guidance
                    </Typography>
                </div>
                <div className="Benifits">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            fontSize: "64px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "94px",
                        }}
                    >
                        Benefits
                    </Typography>
                    <Typography
                        className="benefit-text"
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            fontSize: "40px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "94px",
                        }}
                    >
                        Improving Healthcare. Reducing Costs
                    </Typography>
                </div>
                <div className="firstpole">
                    <div
                        className="side-pole"
                        style={{
                            border: "1px solid red",
                            height: "188px",
                            width: "10px",
                            color: "#E73A56",
                            background: "#E73A56",
                            borderRadius: "5px",
                            marginLeft: "2%",
                            // padding: "2rem",
                        }}
                    ></div>
                    <div className="text1">
                        <Typography
                            className="pole-text"
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "46px",
                            }}
                        >
                            OPTIMIZE TREATMENT & AVOID UNNECESSARY RISKS
                        </Typography>
                        <Typography
                            className="descr-pole"
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Beyond the diagnosis, a second opinion provides us with a chance to ask
                            questions, understand the options, and help in deciding whether to
                            proceed with a potentially risky therapy or not and thereby restore
                            confidence that the treatment plan recommended is appropriate.
                        </Typography>
                    </div>
                </div>
                <div className="secondpole">
                    <div
                        className="second-side-pole"
                        style={{
                            border: "1px solid red",
                            height: "188px",
                            width: "10px",
                            color: "#E73A56",
                            background: "#E73A56",
                            borderRadius: "5px",
                            marginLeft: "2%",
                            // padding: "2rem",
                        }}
                    ></div>
                    <div className="text1">
                        <Typography
                            className="pole-text"
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "46px",
                            }}
                        >
                            COST SAVING FROM AVOIDING UNNECESSARY SURGERY
                        </Typography>
                        <Typography
                            className="descr-pole"
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Good medical services provide smart, proactive, and informed choices
                            that patients can trust with confidence
                        </Typography>
                    </div>
                </div>
                <div className="thirdpole">
                    <div
                        className="third-side-pole"
                        style={{
                            border: "1px solid red",
                            height: "188px",
                            width: "10px",
                            color: "#E73A56",
                            background: "#E73A56",
                            borderRadius: "5px",
                            marginLeft: "2%",
                            // padding: "2rem",
                        }}
                    ></div>
                    <div className="text1">
                        <Typography
                            className="pole-text"
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "46px",
                            }}
                        >
                            FROM THE COMFORT OF YOUR HOME
                        </Typography>
                        <Typography
                            className="descr-pole"
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            The advantages of second opinions are many: financial, physical and
                            psychological. Providing these services remotely via our cutting-edge
                            HIPAA-compliant technology, XperTeleConsult™ system, will improve
                            patient access to medical care and no need for fixing appointments,
                            waiting at the clinic, privacy, etc.
                        </Typography>
                    </div>
                </div>
                <div className="fourthpole">
                    <div
                        className="fourth-side-pole"
                        style={{
                            border: "1px solid red",
                            height: "188px",
                            width: "10px",
                            color: "#E73A56",
                            background: "#E73A56",
                            borderRadius: "5px",
                            marginLeft: "2%",
                            // padding: "2rem",
                        }}
                    ></div>
                    <div className="text1">
                        <Typography
                            className="pole-text"
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "46px",
                            }}
                        >
                            IMPROVE HEALTHCARE OUTCOMES
                        </Typography>
                        <Typography
                            className="descr-pole"
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Second opinions have been found to bring down cost of healthcare and
                            reduces misdiagnoses
                        </Typography>
                    </div>
                </div>
                <div className="heading2">
                    <Typography
                        className="head2"
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            fontSize: "64px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "94px",
                        }}
                    >
                        HEAR FROM THE EXPERTS
                    </Typography>
                    <Typography
                        className="expert-text"
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            fontSize: "34px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "94px",
                        }}
                    >
                        This is what the experts say about our platform
                    </Typography>
                </div>

                <div className="monitor-doctor">
                    <Box
                        component={"img"}
                        src={ScrewDoctor}
                        style={{ width: "695px", height: "479px" }}
                    ></Box>
                </div>

                <Box
                    style={{
                        width: "100%",
                        // border: "1px solid",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <div className="thought">
                        <Typography
                            className="thought-text"
                            style={{
                                color: "#313033",
                                fontFamily: "Red Hat Display",
                                fontSize: "24px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "30px",
                                // backgroundImage: URL(),
                            }}
                        >
                            “Time and health are two precious assets that we don’t recognize and
                            appreciate until they have been depleted.” <br></br>– Denis Waitley
                        </Typography>
                    </div>
                </Box>
                <div className="pink-container">
                    <div className="boximage">
                        <Box
                            component={"img"}
                            src={Alldoctor}
                            style={{ width: "500px", height: "450px" }}
                        ></Box>
                    </div>
                    <div className="welcome-heading">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "36px",
                            }}
                        >
                            Welcome to our medical app,<br></br>
                            where your health is our priority!
                        </Typography>
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Meet our exceptional team behind the innovative healthcare app<br></br>
                            that is revolutionizing the way you manage your health. <br></br>
                            Comprised of dedicated professionals from various fields, we are
                            <br></br>
                            united by a common goal: to provide you with the best possible <br></br>
                            care and support on your wellness journey.
                        </Typography>
                    </div>
                </div>

                {/*responsivev container starts  */}
                <div className="pink-container-responsive">
                    <div className="welcome-heading">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "36px",
                                textAlign: "center",
                            }}
                        >
                            Welcome to our medical <br />
                            app, where your health is <br />
                            our priority!
                        </Typography>
                        <div className="boximage">
                            <Box
                                component={"img"}
                                src={Alldoctor}
                                style={{ width: "500px", height: "450px" }}
                            ></Box>
                        </div>
                        <Typography
                            className="alldoc-text"
                            style={{
                                color: "#313033",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                                textAlign: "center",
                            }}
                        >
                            Meet our exceptional team behind the innovative healthcare app<br></br>
                            that is revolutionizing the way you manage your health. <br></br>
                            Comprised of dedicated professionals from various fields, we are
                            <br></br>
                            united by a common goal: to provide you with the best possible <br></br>
                            care and support on your wellness journey.
                        </Typography>
                    </div>
                </div>
                {/* responsive container ends */}

                <div className="red-footer">
                    <div className="first-ali">
                        <div className="first-half">
                            <Box
                                component={"img"}
                                src={Whitelogo}
                                style={{ width: "245px", height: "42px" }}
                            ></Box>
                        </div>
                        <div className="half-align">
                            <Typography
                                style={{
                                    color: "#FFF",
                                    fontFamily: "Red Hat Display ",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                }}
                            >
                                Doctor Registration
                            </Typography>
                            <Typography
                                style={{
                                    color: "#FFF",
                                    fontFamily: "Red Hat Display ",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                }}
                            >
                                How it Works?
                            </Typography>
                        </div>
                    </div>
                    <div className="hcf-pr">
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            HCF Registration
                        </Typography>
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            Pricing
                        </Typography>
                    </div>

                    <div className="about-us">
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            About Us
                        </Typography>
                    </div>
                    <div className="social-icons">
                        <LinkedIn style={{ color: "white" }} />
                        <Twitter style={{ color: "white" }} />
                        <Instagram style={{ color: "white" }} />
                        <Facebook style={{ color: "white" }} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Howitworks;
