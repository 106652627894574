import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import "./patientcompleteprofile.scss";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { TextField } from "@material-ui/core";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../components/CustomTextField/custom-text-field";
import CustomDropdown from "../../components/CustomDropdown/custom-dropdown";
import CustomButton from "../../components/CustomButton";
import { baseURL } from "../../constants/const";
import CustomSnackBar from "../../components/CustomSnackBar";
import ClassicFrame from "../../constants/DrImages/Undraw.png";
import ImageFrame from "../../constants/DrImages/Frame.png";
import axiosInstance from "../../config/axiosInstance";

const steps = ["", ""];

const PatientCompleteProfile = () => {
    const dropdownItems = ["Male", "Female", "Others"];
    const [activeDropdown, setActiveDropdown] = useState("");
    const navigate = useNavigate();
    function getWeeksAfter(date, amount) {
        return date ? date.add(amount, "week") : undefined;
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [value, setValue] = useState([null, null]);
    const radioValues = ["My self"];
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [countryValues, setCountryValue] = useState([]);
    const [countryNames, setCountryNames] = useState(["Please Wait"]);
    const [stateValue, setStateValue] = useState([]);
    const [stateName, setStateName] = useState("");
    const [stateNames, setStateNames] = useState(["Please Wait"]);
    const [cityValues, setCityValues] = useState([]);
    const [cityNames, setCityNames] = useState([]);
    const [selectedCountryFromDropDown, setSelectedCountryFromDropDown] = useState([]);
    const [selectCityFromDropDown, setSelectCityFromDropDown] = useState([]);
    const [citySelected, setCitySelected] = useState("");
    const [data, setData] = useState({
        email: localStorage.getItem("patient_Email"),
        first_name: null,
        last_name: null,
        middle_name: null,
        added_by: "self",
        gender: null,
        DOB: null,
        country_id: null,
        state_id: null,
        city_id: null,
        street_address1: null,
        street_address2: null,
        zip_code: null,
    });

    const fetchData = async () => {
        try {
            // const response = await axiosInstance.post("/sec/auth/login",JSON.stringify(data),{"Accept" : "Application/json"});
            const response = await axios.post(
                `${baseURL}/sec/auth/updateProfile`,
                JSON.stringify(data),
                { Accept: "Application/json" },
            );
            setOpenSnackBar(true);
            handleNext();
            console.log("Responase : ", response);
        } catch (error) {
            console.log(error);
        }
    };

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    console.log("data : ", data);

    useEffect(() => {
        FetchCountryNames();
        FetchStateNames();
    }, []);

    const FetchCountryNames = async () => {
        let CountryValues = [];
        let CountryName = [];
        try {
            const response = await axiosInstance("/sec/countries");
            for (let key in response?.data?.response) {
                CountryValues.push(response?.data?.response[key]);
                CountryName.push(response?.data?.response[key].country_name);
            }
            setCountryNames(CountryName);
            setCountryValue(CountryValues);
        } catch (error) {
            console.log(error);
        }
    };

    const FetchStateNames = async (country_id) => {
        let StateValues = [];
        let StateName = [];
        try {
            const response = await axiosInstance(`/sec/states?country_id=${country_id}`);
            for (let key in response?.data?.response) {
                StateValues.push(response?.data?.response[key]);
                StateName.push(response?.data?.response[key].state_name);
            }
            setStateValue(StateValues);
            setStateNames(StateName);
        } catch (error) {
            console.log(error);
        }
    };
    // run the api call when there is change in country drop down
    useEffect(() => {
        FetchStateNames(selectedCountryFromDropDown[0]?.country_id);
    }, [selectedCountryFromDropDown]);

    const FetchCityNames = async (state_id) => {
        let CityValues = [];
        let cityName = [];
        try {
            const response = await axiosInstance(`/sec/cities?state_id=${state_id}`);
            console.log("response city id : ", response);
            for (let key in response?.data?.response) {
                CityValues.push(response?.data?.response[key]);
                cityName.push(response?.data?.response[key].city_name);
            }
            setCityValues(CityValues);
            setCityNames(cityName);
        } catch (error) {
            console.log(error);
        }
    };
    // run the api to fetch the city details
    useEffect(() => {
        FetchCityNames(selectCityFromDropDown[0]?.state_id);
    }, [selectCityFromDropDown]);

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <CustomSnackBar
                    isOpen={openSnackBar}
                    type={"success"}
                    message={"Profile Completed Successfully"}
                />
                <div className="FrameBox1">
                    <Box
                        // sx={{ borderRadius: "8px", width: "100%", height: "100%" }}
                        component={"img"}
                        src={ImageFrame}
                    ></Box>
                </div>

                <div className="step-back">
                    <div className="back-btn">
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1, color: "red" }}
                        >
                            Back
                        </Button>
                    </div>
                    <div className="Stepper">
                        <Stepper
                            activeStep={activeStep}
                            style={{
                                width: "700px",
                            }}
                        >
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                if (isStepOptional(index)) {
                                }
                                if (isStepSkipped(index)) {
                                    stepProps.completed = false;
                                }
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </div>
                </div>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                {/* Put component here */}
                                <center>
                                    We express our gratitude for your diligence in completing the
                                    patient profile. Your attention to detail contributes
                                    significantly to our records
                                </center>
                            </Typography>
                            <CustomButton
                                label="Click here to login"
                                handleClick={() => navigate("/patientlogin")}
                                buttonCss={{ width: "fit-content" }}
                            />
                        </Box>
                        {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box> */}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            {/* Step {activeStep + 1} */}
                            {activeStep === 0 ? (
                                <>
                                    <Box>
                                        <div className="mainBox1">
                                            <Box sx={{width : "40%"}} >
                                            <div className="heading1">
                                                <Typography
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "25px",
                                                        fontStyle: "normal",
                                                        fontWeight: "500",
                                                        lineHeight: "30px",
                                                    }}
                                                >
                                                    Personal Information
                                                </Typography>
                                            </div>
                                            <div className="Text-fields1">
                                                <TextField
                                                    label="First Name"
                                                    variant="standard"
                                                    required={"required"}
                                                    style={{
                                                        width: "50%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) =>
                                                        setData({
                                                            ...data,
                                                            first_name: event?.target?.value,
                                                        })
                                                    }
                                                ></TextField>
                                                <TextField
                                                    label="Middle Name"
                                                    variant="standard"
                                                    required={"required"}
                                                    style={{
                                                        width: "50%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) =>
                                                        setData({
                                                            ...data,
                                                            middle_name: event?.target?.value,
                                                        })
                                                    }
                                                ></TextField>
                                            </div>
                                            <div className="other-fields1">
                                                <TextField
                                                    label="Last Name"
                                                    variant="standard"
                                                    required={"required"}
                                                    style={{
                                                        width: "100%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) =>
                                                        setData({
                                                            ...data,
                                                            last_name: event?.target?.value,
                                                        })
                                                    }
                                                ></TextField>
                                            </div>
                                            <div className="Date-of-birth1">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={["DatePicker"]}>
                                                        <DatePicker
                                                            label="Date of Birth"
                                                            // style={{ width: "300px" }}
                                                            onChange={(value) => {
                                                                console.log(value?.$d);
                                                                setData({
                                                                    ...data,
                                                                    DOB: value?.$d,
                                                                });
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className="gender1">
                                                <CustomDropdown
                                                    label={"Gender"}
                                                    items={dropdownItems}
                                                    activeItem={activeDropdown}
                                                    handleChange={(item) => {
                                                        setActiveDropdown(item);
                                                        console.log(item);
                                                        setData({ ...data, gender: item });
                                                    }}
                                                    dropdowncss={{
                                                        width: "100%",
                                                        color: "#787579",
                                                    }}
                                                />
                                            </div>
                                            </Box>
                                            <div className="sve-btn">
                                                <CustomButton
                                                    handleClick={handleNext}
                                                    label="Next"
                                                    buttonCss={{
                                                        width: "33%",
                                                    }}
                                                    isDisabled={ ((data?.first_name != null) && (data?.last_name != null )&& (data?.gender != null) && (data?.DOB != null)) ? false : true }
                                                >
                                                    {activeStep === steps.length - 1
                                                        ? "Finish"
                                                        : "Next"}
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : activeStep === 1 ? (
                                <>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <div className="mainBox1">
                                            <Box sx={{width : "40%"}} >
                                            <div className="heading1">
                                                <Typography
                                                    style={{
                                                        fontFamily: "poppins",
                                                        fontSize: "25px",
                                                        fontStyle: "normal",
                                                        fontWeight: "500",
                                                        lineHeight: "30px",
                                                    }}
                                                >
                                                    Contact Information
                                                </Typography>
                                            </div>
                                            <div className="Text-fields1">
                                                <TextField
                                                    label="House No"
                                                    variant="standard"
                                                    style={{
                                                        width: "100%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) => {}}
                                                ></TextField>
                                            </div>
                                            <div className="other-fields1">
                                                <TextField
                                                    label="Street Line 1"
                                                    variant="standard"
                                                    style={{
                                                        width: "100%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) => {
                                                        setData({
                                                            ...data,
                                                            street_address1: event.target.value,
                                                        });
                                                    }}
                                                ></TextField>
                                            </div>
                                            <div className="other-fields1">
                                                <TextField
                                                    label="Street Line 2"
                                                    variant="standard"
                                                    style={{
                                                        width: "100%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) => {
                                                        setData({
                                                            ...data,
                                                            street_address2: event.target.value,
                                                        });
                                                    }}
                                                ></TextField>
                                            </div>
                                            <div className="Degree">
                                                <Box sx={{ width: "100%", padding: "1%" }}>
                                                    <CustomDropdown
                                                        label={"Country"}
                                                        dropdowncss={{ width: "100%" }}
                                                        items={countryNames}
                                                        minwidthDropDown="300px"
                                                        activeItem={activeDropdown}
                                                        handleChange={(listItems) => {
                                                            setActiveDropdown(listItems);
                                                            let response = countryValues.filter(
                                                                (country) =>
                                                                    country?.country_name?.includes(
                                                                        listItems,
                                                                    ),
                                                            );
                                                            console.log(
                                                                "Country response : ",
                                                                response[0]?.country_id,
                                                            );
                                                            setData({
                                                                ...data,
                                                                country_id: response[0]?.country_id,
                                                            });
                                                            setSelectedCountryFromDropDown(
                                                                response,
                                                            );
                                                        }}
                                                        // dropdowncss={{ width:"300px" }}
                                                    />
                                                </Box>
                                            </div>
                                            <div className="Degree">
                                                <Box sx={{ width: "100%", padding: "1%" }}>
                                                    <CustomDropdown
                                                        label={"State"}
                                                        dropdowncss={{ width: "100%" }}
                                                        items={stateNames}
                                                        minwidthDropDown="300px"
                                                        activeItem={stateName}
                                                        handleChange={(listItems) => {
                                                            let response = stateValue.filter(
                                                                (state) =>
                                                                    state?.state_name?.includes(
                                                                        listItems,
                                                                    ),
                                                            );
                                                            // console.log("State ID : " , response[0].state_id)
                                                            setData({
                                                                ...data,
                                                                state_id: response[0]?.state_id,
                                                            });
                                                            setSelectCityFromDropDown(response);
                                                            setStateName(listItems);
                                                        }}
                                                        // dropdowncss={{ width:"300px" }}
                                                    />
                                                </Box>
                                            </div>

                                            <div className="Degree">
                                                <Box sx={{ width: "100%", padding: "1%" }}>
                                                    <CustomDropdown
                                                        label={"City"}
                                                        dropdowncss={{ width: "100%" }}
                                                        items={cityNames}
                                                        minwidthDropDown="300px"
                                                        activeItem={citySelected}
                                                        handleChange={(listItems) => {
                                                            setCitySelected(listItems);
                                                            let response = cityValues.filter(
                                                                (city) =>
                                                                    city?.city_name?.includes(
                                                                        listItems,
                                                                    ),
                                                            );
                                                            setData({
                                                                ...data,
                                                                city_id: response[0]?.city_id,
                                                            });
                                                        }}
                                                        // dropdowncss={{ width:"300px" }}
                                                    />
                                                </Box>
                                            </div>

                                            <div className="Degree">
                                                <TextField
                                                    label="Zip Code"
                                                    variant="standard"
                                                    style={{
                                                        width: "100%",
                                                        color: "#787579",
                                                    }}
                                                    onInput={(event) => {
                                                        setData({
                                                            ...data,
                                                            zip_code: event.target.value,
                                                        });
                                                    }}
                                                ></TextField>
                                            </div>
                                            </Box>
                                            <div className="sve-btn">
                                                <CustomButton
                                                    handleClick={fetchData}
                                                    label="Save"
                                                    buttonCss={{
                                                        width: "33%",
                                                    }}
                                                    isDisabled={ ((data?.street_address1 != null) && (data?.country_id != null )&& (data?.city_id != null) && (data?.state_id != null)&& (data?.zip_code != null)) ? false : true }

                                                >
                                                    {/* {activeStep === steps.length - 1
                                                        ? "Finish"
                                                        : "Next"} */}
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : (
                                <h1>Completed</h1>
                            )}
                            ;
                        </Typography>
                        {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {isStepOptional(activeStep) && (
                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                    Skip
                                </Button>
                            )}
                        </Box> */}
                    </React.Fragment>
                )}
            </Box>
        </>
    );
};

export default PatientCompleteProfile;
