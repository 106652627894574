import React, { useState } from "react";
import ImageLogo from "../../../static/images/logo.png";
import { Box, Typography } from "@mui/material";
// import BurgerImage from "../../constants/DrImages/Glyph_ undefined.png";
import "./Home.scss";
import FirstImage from "../HomeImages/rectangle11.png";
import FirstStepImage from "../HomeImages/Step1.png";
import SecondStepImage from "../HomeImages/Step2.png";
import ThirdStepImage from "../HomeImages/Step3.png";
import SecondRowImage from "../HomeImages/manydoctors.png";
import LastImage from "../HomeImages/lastcontent.png";
import ThirdImage from "../HomeImages/thirdcontent.png";
import CustomButton from "../../../components/CustomButton/custom-button";
import whiteframe from "../HomeImages/whiteFrame.png";
import AllDoctors from "../HomeImages/Alldoctors.png";
import { LinkedIn } from "@mui/icons-material";
import { Twitter } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Facebook } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

const Homes = () => {
    const navigate = useNavigate();
    const [showBurger, setShowBurgerMenu] = useState(true);
    const handleSubmit = (e) => {
        e.preventDefault();
        navigate("/selectprofiletype");
    };

    function toggleMenu() {
        setShowBurgerMenu(!showBurger);
        const menu = document.querySelector(".other-header-content-two");
        // menu.classList.toggle("show");
        showBurger ? (menu.style.display = "none") : (menu.style.display = "block");
    }

    return (
        <>
            <div className="screen-cntr1">
                <div className="header-container1">
                    <Box component={"img"} src={ImageLogo}></Box>
                    <div className="other-header-content">
                        <Link to={"/howitworks"} style={{ textDecoration: "none" }}>
                            <Typography
                                style={{
                                    color: "#373737",
                                    fontFamily: "Poppins",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "36px",
                                }}
                            >
                                How it works?
                            </Typography>
                        </Link>
                        <Link to={"/aboutlanding"} style={{ textDecoration: "none" }}>
                            <Typography
                                style={{
                                    color: "#373737",
                                    fontFamily: "Poppins",
                                    fontSize: "18px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "36px",
                                }}
                            >
                                About Us
                            </Typography>
                        </Link>
                        <Typography
                            style={{
                                color: "#373737",
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "36px",
                            }}
                        >
                            Pricing
                        </Typography>
                        <CustomButton
                            label="Sign Up"
                            buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                            isTransaprent={"True"}
                            handleClick={() => {
                                navigate("/selectsignup");
                            }}
                        ></CustomButton>
                        <CustomButton
                            label="Login"
                            buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                            handleClick={handleSubmit}
                        ></CustomButton>

                        <div className="burger-menu" onClick={toggleMenu}>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                </div>
                <div className="other-header-content-two">
                    <Link to={"/howitwork"} style={{ textDecoration: "none" }}>
                        <Typography
                            style={{
                                color: "#373737",
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "36px",
                            }}
                        >
                            How it works?
                        </Typography>
                    </Link>
                    <Link to={"/aboutlanding"} style={{ textDecoration: "none" }}>
                        <Typography
                            style={{
                                color: "#373737",
                                fontFamily: "Poppins",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "36px",
                            }}
                        >
                            About Us
                        </Typography>
                    </Link>
                    <Typography
                        style={{
                            color: "#373737",
                            fontFamily: "Poppins",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "36px",
                        }}
                    >
                        Pricing
                    </Typography>
                    <CustomButton
                        label="Sign Up"
                        buttonCss={{ width: "170px", height: "38px", borderRadius: "20px" }}
                        isTransaprent={"True"}
                        handleClick={() => {
                            navigate("/selectsignup");
                        }}
                    ></CustomButton>
                    <CustomButton
                        label="Login"
                        buttonCss={{
                            width: "170px",
                            height: "38px",
                            borderRadius: "20px",
                            marginTop: "0.5%",
                        }}
                        handleClick={handleSubmit}
                    ></CustomButton>

                    <div className="burger-menu" onClick={toggleMenu}>
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
                <div className="first-row-content">
                    <div className="title-heading">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",
                                fontSize: "64px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "94px",
                            }}
                        >
                            Find Specialist Doctor<br></br>
                            For Your Every Need At<br></br>
                            Remote Doctors Online
                        </Typography>

                        <Typography
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                // textAlign: "center",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            We can find the doctors for you and book your appointment with easy
                            <br></br>
                            care click process.
                        </Typography>
                        <CustomButton
                            label="Book Your Appointments"
                            buttonCss={{ width: "230px", height: "38px" }}
                        ></CustomButton>
                    </div>
                    <Box component={"img"} src={FirstImage}></Box>
                </div>
                <div className="steps-title">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            // textAlign: "center",
                            fontSize: "36px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "46px",
                        }}
                    >
                        Three Easy Steps To<br></br>
                        Find Your doctor
                    </Typography>
                </div>
                <div className="Steps-Images">
                    <Box component={"img"} src={FirstStepImage}></Box>
                    <Box component={"img"} src={SecondStepImage}></Box>
                    <Box component={"img"} src={ThirdStepImage}></Box>
                </div>
                <div className="steps-data">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            // textAlign: "center",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "46px",
                        }}
                    >
                        Search Doctor
                    </Typography>
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            // textAlign: "center",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "46px",
                        }}
                    >
                        Check Availability
                    </Typography>
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",
                            // textAlign: "center",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "46px",
                        }}
                    >
                        Book your Appointments
                    </Typography>
                </div>
                <div className="steps-description">
                    <Typography
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            // textAlign: "center",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        Lorem ipsum dolor sit amet,<br></br>
                        consectetur adipiscing elit, sed do<br></br>
                        eiusmod tempor incididunt ut<br></br>
                        labore .
                    </Typography>
                    <Typography
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        Lorem ipsum dolor sit amet,<br></br>
                        consectetur adipiscing elit, sed do<br></br>
                        eiusmod tempor incididunt ut<br></br>
                        labore .
                    </Typography>
                    <Typography
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        Lorem ipsum dolor sit amet,<br></br>
                        consectetur adipiscing elit, sed do<br></br>
                        eiusmod tempor incididunt ut<br></br>
                        labore .
                    </Typography>
                </div>
                <div className="second-row-content">
                    <Box component={"img"} src={SecondRowImage}></Box>
                    <div className="second-side-content">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",

                                fontSize: "36px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "46px",
                            }}
                        >
                            Quality Health Starts<br></br>
                            With Quality Doctors
                        </Typography>
                        <Typography
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing<br></br>
                            elit, sed do eiusmod tempor incididunt ut labore et<br></br>
                            dolore magna aliqua<br></br>
                            Ut enim ad minim veniam, quis nostrud exercitation
                        </Typography>
                        <CustomButton
                            label="Find Doctor "
                            buttonCss={{ width: "170px", height: "38px" }}
                        />
                    </div>
                </div>
                <div className="second-row-content">
                    <div className="second-side-content">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",

                                fontSize: "36px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "46px",
                            }}
                        >
                            Find your Desired Doctors <br></br>
                            Anytime,Anywhere!
                        </Typography>
                        <Typography
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing<br></br>
                            elit, sed do eiusmod tempor incididunt ut labore et<br></br>
                            dolore magna aliqua<br></br>
                            Ut enim ad minim veniam, quis nostrud exercitation
                        </Typography>
                        <CustomButton
                            label="Find Doctor "
                            buttonCss={{ width: "170px", height: "38px" }}
                        />
                    </div>

                    <Box
                        component={"img"}
                        src={ThirdImage}
                        style={{ width: "480px", height: "325px" }}
                    ></Box>
                </div>
                <div className="second-row-content">
                    <Box
                        component={"img"}
                        src={LastImage}
                        style={{ width: "480px", height: "325px" }}
                    ></Box>
                    <div className="second-side-content">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "playfair Display",

                                fontSize: "36px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "46px",
                            }}
                        >
                            Book your Appointment<br></br>
                            With just click
                        </Typography>
                        <Typography
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing<br></br>
                            elit, sed do eiusmod tempor incididunt ut labore et<br></br>
                            dolore magna aliqua<br></br>
                            Ut enim ad minim veniam, quis nostrud exercitation
                        </Typography>
                        <CustomButton
                            label="Book Appointment "
                            buttonCss={{
                                width: "200px",
                                height: "38px",
                                fontFamily: "poppins",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "22px",
                            }}
                        />
                    </div>
                </div>
                <div className="About-content">
                    <Typography
                        style={{
                            color: "#313033",
                            fontFamily: "playfair Display",

                            fontSize: "36px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "46px",
                        }}
                    >
                        About Us
                    </Typography>
                </div>
                <div className="about-descript">
                    <Typography
                        style={{
                            color: "#525152",
                            fontFamily: "Red Hat Display",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "27px",
                        }}
                    >
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br></br>
                        eiusmod tempor incididunt ut labore .
                    </Typography>
                </div>
                <div className="box-cont">
                    <Box
                        component={"img"}
                        src={AllDoctors}
                        style={{ width: "600px", height: "600px" }}
                    ></Box>
                    <div className="title-descr">
                        <Typography
                            style={{
                                color: "#313033",
                                fontFamily: "Playfair Display",
                                fontSize: "34px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "36px",
                            }}
                        >
                            Welcome to our medical app,<br></br>
                            where your health is our priority!
                        </Typography>
                        <Typography
                            style={{
                                color: "#525152",
                                fontFamily: "Red Hat Display",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "27px",
                            }}
                        >
                            Meet our exceptional team behind the innovative healthcare app <br></br>
                            that is revolutionizing the way you manage your health.<br></br>
                            Comprised of dedicated professionals from various fields, we are
                            <br></br>
                            united by a common goal: to provide you with the best possible<br></br>
                            care and support on your wellness journey.
                        </Typography>
                    </div>
                </div>
                <div className="red-footer">
                    <div className="first-ali">
                        <div className="first-half">
                            <Box
                                component={"img"}
                                src={whiteframe}
                                style={{ width: "245px", height: "42px" }}
                            ></Box>
                        </div>
                        <div className="half-align">
                            <Typography
                                style={{
                                    color: "#FFF",
                                    fontFamily: "Red Hat Display ",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                }}
                            >
                                Doctor Registration
                            </Typography>
                            <Typography
                                style={{
                                    color: "#FFF",
                                    fontFamily: "Red Hat Display ",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "24px",
                                }}
                            >
                                How it Works?
                            </Typography>
                        </div>
                    </div>
                    <div className="hcf-pr">
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            HCF Registration
                        </Typography>
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            Pricing
                        </Typography>
                    </div>

                    <div className="about-us">
                        <Typography
                            style={{
                                color: "#FFF",
                                fontFamily: "Red Hat Display ",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }}
                        >
                            About Us
                        </Typography>
                    </div>
                    <div className="social-icons">
                        <LinkedIn style={{ color: "white" }} />
                        <Twitter style={{ color: "white" }} />
                        <Instagram style={{ color: "white" }} />
                        <Facebook style={{ color: "white" }} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Homes;
