import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomButton from "../../../../components/CustomButton";
import pen from "../../../../constants/DrImages/Pen.svg";
import { PatientSearchTable } from "../DiagnosticPatientSearch/PatientSearchTable";
import { ShareModal } from "./ShareModal";
import axiosInstance from "../../../../config/axiosInstance";
import DiagnostCenterTableCard from "./DiagnostCenterTableCard";
import CustomModal from "../../../../components/CustomModal";
import CustomTextField from "../../../../components/CustomTextField";
import CustomDropdown from "../../../../components/CustomDropdown";
import "./sharelist.scss";
// import { CloudUploadIcon } from "@heroicons/react/outline"; "@mui/icons-material"
import { CloudUploadIcon } from "@heroicons/react/outline";

function createData(name, calories, fat, carbs, protein, action) {
    return { name, calories, fat, carbs, protein, action };
}
const rows = [
    createData(
        <PatientSearchTable name={"Patient Name"} Id={"001"} />,

        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <div>
            <ShareModal />
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),
    createData(
        <PatientSearchTable name={"Patient Name"} Id={"001"} />,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <div>
            <CustomButton label="share" isTransaprent />
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),
];
const ShareList = () => {
    const [cardData, setCardData] = useState([]);
    const [staff_id, setStaff_id] = useState(localStorage.getItem("diagnostic_suid"));
    const [openDialog, setOpenDialog] = useState(false);
    const [labItems , setLabitems] = useState([]);
    const [activeItem , setActiveItem] = useState("")

    const fetchData = async () => {
        try {
            const resp = await axiosInstance(`/sec/hcf/reportShareList/${staff_id}`);
            console.log("share list: ", resp?.data?.response);
            setCardData(resp?.data?.response);
        } catch (err) {
            console.log("Error : ", err);
        }
    };


    const fetchLabsDepartments = async () => {
        try {
            const resp = await axiosInstance(`/sec/hcf/getHcfLabs/${staff_id}`);
            console.log("share list: ", resp?.data?.response);
            setLabitems(resp?.data?.response);
        } catch (err) {
            console.log("Error : ", err);
        }
    };


    useEffect(() => {
        fetchData();
        fetchLabsDepartments();
    }, []);


    // /sec/hcf/testReportUpload

    const postReport = async (test_id,pdfFileName,pdfExtension) => {
        console.log("test id : ",test_id);
        console.log("pdf File : ",pdfFileName);
        console.log("pdf Extension : ",pdfExtension)
        try {
            const resp = await axiosInstance.post(`/sec/hcf/testReportUpload`,JSON.stringify({
                test_id : String(test_id),
                fileName: pdfFileName,
                fileExtension: pdfExtension,
                staff_id: staff_id
            }));
            console.log("post Reports : ", resp?.data?.response);
        } catch (err) {
            console.log("Error : ", err);
        }
    };

    return (
        <>
            <TableContainer component={Paper} style={{ background: "white" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name/Booking ID</TableCell>
                            <TableCell align="right">Date & time</TableCell>
                            <TableCell align="right">Test Name</TableCell>
                            <TableCell align="right">Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cardData.length > 0 ? (
                            cardData?.map((data) => {
                                let pdfFileName = "";
                                let pdfExtension = "";
                                return (
                                <TableRow
                                    key={data?.suid}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <DiagnostCenterTableCard
                                            id={data?.suid}
                                            name={data?.first_name + " " + data?.last_name}
                                        />
                                    </TableCell>
                                    <TableCell align="right">{data?.book_date}</TableCell>
                                    <TableCell align="right">{data?.test_name}</TableCell>
                                    <TableCell align="right">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span>
                                                <CustomButton
                                                    label={"Share"}
                                                    isElevated
                                                    handleClick={() => setOpenDialog(!openDialog)}
                                                    isTransaprent={true}
                                                    buttonCss={{ borderRadius: "50px" }}
                                                />
                                                <CustomModal
                                                    isOpen={openDialog}
                                                    title={"Share"}
                                                    class_name={"share_list_modal"}
                                                    disableBackdropClick={true} // or false based on your needs
                                                    conditionOpen={setOpenDialog} // Ensure this is passed to handle modal close
                                                    maincontainerclassname={"share_list_modal"}
                                                    footer={
                                                        <Box className={"share_list_modal"}>
                                                            <Box
                                                                sx={{
                                                                    width: "100%",
                                                                    display: "content",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    flexDirection: "column",
                                                                }}
                                                            >
                                                                <CustomTextField
                                                                    id={data?.suid}
                                                                    textcss={{ width: "100%" }}
                                                                    placeholder={
                                                                        "Enter File Name Here"
                                                                    }
                                                                    helperText={""}
                                                                    label=""
                                                                />
                                                                <CustomDropdown
                                                                activeItem={activeItem}
                                                                items={labItems}
                                                                label={"Select Labs"}
                                                                    CustomSx={{
                                                                        width: "100%",
                                                                        marginTop: "2%",
                                                                    }}
                                                                    dropdowncss={{
                                                                        width: "100%",
                                                                        marginTop: "2%",
                                                                    }}
                                                                    handleChange={item => setActiveItem(item)}
                                                                />
                                                             
                                                                    <CustomTextField
                                                                        type={"file"}
                                                                        placeholder={"some file name"}
                                                                        
                                                                        leftIcon={<CloudUploadIcon/>}
                                                                        textcss={{
                                                                            marginTop: "2%",
                                                                        }}
                                                                        helperText={""}
                                                                        onInput={file => {
                                                                            pdfFileName    = file?.target?.value.split(".")[0].split("\\")[file?.target?.value.split(".")[0].split("\\").length -1];
                                                                            pdfExtension = file?.target?.value.split(".")[file?.target?.value.split(".").length - 1]
                                                                            console.log("pdf extnsion : ",pdfExtension)
                                                                            console.log("pdf file name : ",pdfFileName)
                                                                        }}
                                                                    /><br/>
                                                                    <center>
                                                                    <CustomButton
                                                                        label="Share"
                                                                        isTransaprent={true}
                                                                        buttonCss={{
                                                                            borderRadius: "30px",
                                                                            marginTop: "2%",
                                                                        }}
                                                                        handleClick={() => postReport(data?.test_id,pdfFileName,pdfExtension)}
                                                                    />
                                                                    </center>
                                                                   
                                                            </Box>
                                                        </Box>
                                                    }
                                                >
                                                    <Box className="Book-appointment-modal">
                                                        <Box
                                                            component={"div"}
                                                            sx={{ width: "100%", height: "100%" }}
                                                        ></Box>
                                                    </Box>
                                                </CustomModal>
                                            </span>
                                            <span style={{ marginLeft: "1%" }}>{data?.status}</span>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                            }
                        )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default ShareList;
