import {
    Box,
    ButtonBase,
    Stack,
    Typography,
    IconButton,
    TextField,
    InputAdornment,
    Grid,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme, alpha } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CustomDropdown from "../CustomDropdown/custom-dropdown";
import CustomButton from "../CustomButton/custom-button";
// import Card from "../Card/card";
import { data, CallCardData } from "../Card/const1";
import FilterListIcon from "../../static/images/Filterlist.png";
import Drcard from "../../constants/drcard/drcard";
import SearchBarModalCard from "../../constants/SearchBarModalCard/SearchBarModalCard";
import axios from "axios";
import axiosInstance from "../../config/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../constants/const";

// const StrawberyyIconWrapper = styled("div")(({ theme }) => ({
//     padding: theme.spacing(0, "0%"), //    margin: -3em 0% 0% 31%;
//     margin: "-3em 0% 0% 39%",
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
// }));

function rand() {
    return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        position: "absolute",
        width: "50%",
        height: "80%",
        // backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        background: "#ffff",

        padding: theme.spacing(2, 4, 3),
    },
    image: {
        marginRight: "2%",
        marginTop: "5px",
    },
}));

const SearchBarModal = () => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [searchValue , setSearchValue] = useState(null);
    const [card_data , setCard_data] = useState([]);

    const fetchSearchResult = async () => {
        try{
            const resp = await axiosInstance.get(`/sec/patient/getPatientSearchAPI/${searchValue}`);
            setCard_data(resp?.data?.response);
            console.log( resp?.data?.response);
        }catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        fetchSearchResult();
    },[searchValue])

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [age, setAge] = React.useState("");

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const [activeDropdown, setActiveDropdown] = useState("");
    const handleSubmit = (e) => {};

    const inputChangeHandler = (event) => {
        const { value } = event.target; // get the value entered by user in the searchbar
        setSearchValue(value.toLowerCase()); // Set the value to lowercase to get consistant results
        console.log(value);
    };

    // Search api logic using debounce
    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 350);
        };
    };

    // FIXED: A new event handler that delays the searchValue resulting in only one call
    const optimizedSearchHandler = useCallback(debounce(inputChangeHandler), []);

    if (!card_data) return null;

    return (
        <div className="search_bar_modal">
            <Box
                onClick={handleOpen}
                display={"flex"}
                flexWrap={"wrap"}
                border={1}
                borderColor="#AEAAAE"
                borderRadius={"25px"}
                width={"27em"}
                height="38px"
                backgroundColor="#E6E1E5"
            >
                <Stack direction="row" alignItems="center" gap={1}>
                    <SearchIcon sx={{ margin: "0 0 0 0%", color: "#AEAAAE" }} />
                    <Typography variant="body1" sx={{ textAlign: "left", color: "#AEAAAE" }}>
                        Search here…
                    </Typography>
                </Stack>
                <img
                    style={{ width: 18, height: 18, margin: "2.5% 0% 0% 54%" }}
                    src={FilterListIcon}
                    alt="Logo"
                />
            </Box>

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div
                    style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate( -50% , -50% )",
                    }}
                    className={classes.paper}
                >
                    <Box>
                        <IconButton
                            onClick={handleClose}
                            sx={{ marginLeft: "96%", marginTop: "-2%" }}
                        >
                            <CloseIcon sx={{ width: "21px", height: "25px", color: "#313033" }} />
                        </IconButton>
                    </Box>

                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        sx={{ color: "#AEAAAE", width: "24px", height: "24px" }}
                                    ></SearchIcon>
                                </InputAdornment>
                            ),

                            disableUnderline: true,
                        }}
                        sx={{
                            background: "#EFEFEF",
                            borderRadius: "50px",
                            border: "none",
                            padding: "1% 2%",
                            width: "60%",
                        }}
                        onInput={optimizedSearchHandler}
                        placeholder="Search here"
                        id="outlined-basic"
                        variant="standard"
                        className="custom-drawer-search-bar"
                    />

                    <Box sx={{ marginRight: "2%" }}>
                        <CustomDropdown
                            label={""}
                            items={[
                                "Select state",
                                "Andaman and Nicobar Islands",
                                "Andhra Pradesh",
                                "Arunachal Pradesh",
                                "Assam",
                                "Bihar",
                                "Chandigarh",
                                "Chhattisgarh",
                                "Dadra and Nagar Haveli",
                                "Daman and Diu",
                                "Delhi",
                                "Goa",
                                "Gujarat",
                                "Haryana",
                                "Himachal Pradesh",
                                "Jammu and Kashmir",
                                "Jharkhand",
                                "Karnataka",
                                "Kerala",
                                "Ladakh",
                                "Lakshadweep",
                                "Madhya Pradesh",
                                "Maharashtra",
                                "Manipur",
                                "Meghalaya",
                                "Mizoram",
                                "Nagaland",
                                "Odisha",
                                "Puducherry",
                                "Punjab",
                                "Rajasthan",
                                "Sikkim",
                                "Tamil Nadu",
                                "Telangana",
                                "Tripura",
                                "Uttar Pradesh",
                                "Uttarakhand",
                                "West Bengal",
                            ]}
                            minwidthDropDown="430px"
                            activeItem={activeDropdown}
                            handleChange={(listItems) => setActiveDropdown(listItems)}
                            // dropdowncss={{ width:"300px" }}
                        />
                    </Box>
                    <Box
                        sx={{
                            borderBottom: "1px solid",
                            borderColor: "#E6E1E5",
                            height: "4%",
                        }}
                    ></Box>
                    {/* Search Cards */}
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            height: "70%",
                            justifyContent: "space-evenly",
                            overflowX : "hidden"
                        }}
                    >
                        {  
                            ( (card_data === "No Data Found") || card_data.length === 0) ? <h3>No Such Doctor...</h3> : 
                            card_data.map( (data , index) => {
                                {/* if(index <= 5){ */}
                                    return (
                                            <Link to={`http://localhost:8000/patientdashboard/drdetailscard/${data?.suid}`} onClick={handleClose} style={{cursor : "pointer", width : "45%" , height : "25%" , textDecoration : "none"}} >
                                                <SearchBarModalCard DrData={data} />
                                            </Link>  
                                            )
                                {/* }else null */}
                                }
                            )
                        }
                        {/* <SearchBarModalCard DrData={data} />
                        <SearchBarModalCard DrData={data} />
                        <SearchBarModalCard DrData={data} />
                        <SearchBarModalCard DrData={data} />

                        <SearchBarModalCard DrData={data} />
                        <SearchBarModalCard DrData={data} /> */}
                        {/* <Drcard DrData={data} /> */}
                    </Box>
                </div>
            </Modal>
        </div>
    );
};

export default SearchBarModal;
