import React, { useState } from "react";
import "./patientloginwithotp2.scss";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../components/CustomButton/custom-button";
import OTPInput from "react-otp-input";

const patientloginwithotp2 = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(null);

    // from here

    const handleSubmit = (e) => {
        e.preventDefault();

        navigate("/patientdashboard");
    };
    return (
        <div style={{ width: "100%" }}>
            <div className="register-photo">
                <div className="form-container">
                    <div className="image-holder"></div>

                    <div id="contaiern-2">
                        <div id="container-2-inner">
                            <img src="images/logo.png" alt="Logo" width="200" />

                            {/* <h2 className="text-center"> */}
                            <strong style={{ fontSize : "1.5rem"}} >Please enter OTP</strong>
                            {/* </h2> */}
                            <div id="otp-box-container">
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    inputStyle={{
                                        width: "10%",
                                        height: "3rem",
                                        backgroundColor: "#ffff",
                                        borderRadius: "10px",
                                        fontSize: "1.7rem",
                                        borderColor : "#ffff"
                                    }}
                                    containerStyle={{ justifyContent: "center" }}
                                    renderSeparator={<span style={{ marginLeft: "2%" }}></span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                                <div className="otpsent">
                                    <p>The OTP has been sent to - 0123456789 </p>
                                </div>
                            </div>
                            <div className="form-group2">
                                <CustomButton
                                    label={"Continue"}
                                    isTransaprent={false}
                                    isDisabled={false}
                                    isElevated={false}
                                    handleClick={handleSubmit}
                                    buttonCss={{
                                        width: "25.8em",
                                        height: "3.5em",
                                        padding: "8px 16px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "100px",
                                    }}
                                />
                            </div>
                            <div className="resend-otp">Resend Code</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default patientloginwithotp2;
