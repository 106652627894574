import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { PaginationCard } from "../../../../Dashboard/PatientAppointment/PatientCards";
import { AllDoctorTable } from "../AllDoctors/AllDoctorTable";
import pen from "../../../../constants/DrImages/Pen.svg";
import axiosInstance from "../../../../config/axiosInstance";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
const HCFDoctorActive = () => {
    const [hcf_id] = useState(localStorage.getItem("hcfadmin_suid"));
    const [data, setData] = useState([]);
    const status = 1;

    const [nav_specialization, setNav_spelization] = useState([]);
    const [specializationDoc, setSpecializationDoc] = useState("CARDIOLOGIST");

    const fetchData = async (departmentName) => {
        try {  // active 1 inactive 0   
            const response = await axiosInstance.get(`/sec/hcf/ActiveBlockedClinicDoctors/0/${hcf_id}/${departmentName}`);
            setData(response?.data?.response);
            console.log("All doctor data :", response.data.response);
        } catch (error) {
            console.log(error.response);
        }
    };

    useEffect( () =>{
        fetchData(specializationDoc);
    },[specializationDoc] )

    const navigate = useNavigate();


    // logic for specialiation --------------------------------------
    const navSpecializtion = async () => {
        try {
            const resp = await axiosInstance(`/sec/patient/doctorDeptartments`);
            // console.log(resp?.data?.response);
            setNav_spelization(resp?.data?.response);
        } catch (err) {
            console.log("Nav specialization error : ", err);
        }
    };

    useEffect(() => {
        navSpecializtion();
    }, []);

    const scrollContainerRef = useRef(null);

    const handleScrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
        }
    };

    const handleScrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
        }
    };


    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    width: "98%",
                    height: "100%",
                    flexDirection: "row",
                }}
            >
                <nav className="NavBar-Container-Appoinement">
                    <NavLink to={"/hcfadmin/doctor/alldoctors"}>All Doctors</NavLink>
                    <NavLink to={"/hcfadmin/doctor/active"}>Active</NavLink>
                    <NavLink to={"/hcfadmin/doctor/blocked"}>Blocked</NavLink>
                </nav>

                <Box
                    component={"div"}
                    sx={{
                        position: "relative",
                        top: "4em",
                        width: "100%",
                        display: "flex",
                        height: "100%",
                    }}
                >
                    <Box sx={{ width: "100%", height: "100%" }}>
                        {/*--------------------- Category component starts ---------------------------------*/}
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <div onClick={handleScrollLeft}>
                            {" "}
                            {/* Add left arrow */}
                            <ChevronLeftIcon />
                        </div>
                        <Box
                            sx={{ display: "flex", position: "relative" }} // Add relative positioning
                            className={"horizontal-scroll-container NavBar-Container-one"}
                        >
                            <div
                                ref={scrollContainerRef}
                                style={{ overflowX: "auto", display: "flex" }}
                            >
                            
                                {nav_specialization.map((specialization, index) => (
                                    <CustomButton
                                        key={index}
                                        // to={`/patientdashboard/${specialization?.department_name.toLowerCase()}`}
                                        label={`${specialization?.department_name}`}
                                        isTransaprent={
                                            specialization.department_name.toLowerCase() ===
                                            specializationDoc.toLowerCase()
                                                ? false
                                                : true
                                        }
                                        buttonCss={{
                                            borderRadius: "50px",
                                            padding: "0.3% 6.5%",
                                            marginRight: "1%",
                                            whiteSpace: "normal",
                                        }}
                                        handleClick={() => {
                                            setSpecializationDoc(specialization?.department_name);
                                            console.log("specializaition : ",specialization?.department_name)
                                        }}
                                    >
                                    </CustomButton>
                                ))}
                                {/* <NavLink to={"/more"}>More...</NavLink> */}
                            </div>
                        </Box>
                        <div onClick={handleScrollRight}>
                            {" "}
                            {/* Add right arrow */}
                            <ChevronRightIcon />
                        </div>
                    </Box>
                </Box>


                        
                        {/* <div style={{ display: "flex", margin: "10px" }}>
                            <CustomButton label="All" buttonCss={{ margin: "10px" }} />
                            <CustomButton label="Dentist" buttonCss={{ margin: "10px" }} isTransaprent />
                            <CustomButton label="Neurologist" buttonCss={{ margin: "10px" }} isTransaprent />
                            <CustomButton label="Orthopedic" buttonCss={{ margin: "10px" }} isTransaprent />
                            <CustomButton label="Nutritionist" buttonCss={{ margin: "10px" }} isTransaprent />
                            <CustomButton label="Pediatric" buttonCss={{ margin: "10px" }} isTransaprent />
                            <CustomButton label="More..." buttonCss={{ margin: "10px" }} isTransaprent />
                        </div> */}

                        <TableContainer component={Paper} style={{ background: "white" }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name & Details</TableCell>
                                        <TableCell align="right">Department</TableCell>
                                        <TableCell align="right">Status</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row) => (
                                        <TableRow
                                            key={row.doctor_id} // Ensure uniqueness
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <AllDoctorTable name={row.first_name} Id={row.doctor_id} />
                                            </TableCell>
                                            <TableCell align="right">{row.department_name}</TableCell>
                                            <TableCell align="right">
                                                <CustomButton
                                                    isDisabled={true}
                                                    label={row.clinic_status === 1 ? "Active" : "Blocked"}
                                                    isTransaprent
                                                />
                                            </TableCell>

                                            <TableCell align="right">
                                                <CustomButton label={<img src={pen} alt="Edit" />} isTransaprent />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{ marginTop: "220px", marginLeft: "10px" }}>
                            <PaginationCard />
                        </div>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default HCFDoctorActive;
