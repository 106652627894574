// import React from 'react'
import React, { useState } from "react";
import "./patientforgotpassword.scss";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomTextField from "../../../components/CustomTextField";
import CustomButton from "../../../components/CustomButton/custom-button";
import { baseURL } from "../../../constants/const";
import CustomSnackBar from "../../../components/CustomSnackBar";

const patientforgotpassword = () => {
    // useNavigate hook for navigate
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [showSnack, setShowSnack] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [EmailValidation] = useState(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
    const [disableButton, setDisableButton] = useState(true);
    // from here
    const PatientForgotPassword = async () => {
        setShowSnack(false);
        if (email) {
            console.log("Email submit data : ", email);
            sessionStorage.setItem("EmailForgotPassword", email);
            try {
                const response = await axios.post(
                    `${baseURL}/sec/auth/forgotPassword`,
                    JSON.stringify({ email: email }),
                );
                navigate("/patientforgotpassword2");
            } catch (error) {
                setErrorMessage(error?.response?.data?.error);
                setShowSnack(true);
                // console.log(error?.response?.data?.error);
            }
        } else {
            alert("Enter Email");
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();

    //     navigate("/patientforgotpassword2");
    // };

    return (
        <div className="register-photo">
            <CustomSnackBar
                isOpen={showSnack}
                // actionLabel={"action"}
                // handleAction={() => setShowSnack(true)}
                message={errorMessage}
                type="error"
            />
            <div className="form-container">
                <div className="image-holder"></div>

                <div>
                    <div className="logo1">
                        <img src="images/logo.png" alt="Logo" width="200" />
                    </div>

                    <h2 className="text-center">
                        <strong>Forgot Password</strong> <br />
                        <strong>Please Enter Email</strong>
                    </h2>

                    <div className="component-library ">
                        <div className="items ">
                            <div className="field-center1">
                                <Stack spacing={10} alignItems="center" flexDirection="column">
                                    <CustomTextField
                                        id={"standard-helperText1"}
                                        label={"Enter Email"}
                                        defaultValue={email}
                                        helperText={""}
                                        isValid
                                        onInput={(e) => {
                                            setEmail(e.target.value);
                                            console.log(e.target.value);
                                            if (e.target.value.match(EmailValidation)) {
                                                setDisableButton(false);
                                            } else {
                                                setDisableButton(true);
                                            }
                                        }}
                                        textcss={{
                                            width: "22.5em",
                                            height: "56px",
                                        }}
                                    />
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <div className="form-group1">
                        <CustomButton
                            label={"Continue"}
                            isTransaprent={false}
                            isDisabled={disableButton}
                            isElevated={false}
                            handleClick={PatientForgotPassword}
                            buttonCss={{
                                width: "25.8em",
                                height: "3.5em",
                                padding: "8px 16px",
                                justifyContent: "center",
                                alignItems: "center",

                                borderRadius: "100px",
                                marginLeft: "-70px",
                                marginTop: "37px",
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default patientforgotpassword;
