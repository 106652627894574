import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "../../../../components/CustomButton";
import "./addplan.scss";
import axiosInstance from "../../../../config/axiosInstance";

const AddPlanCard = ({planCardData , index , RendenDataAfterDelete , isDeleteVisible=true , isEditVisible=true }) => {

    const [deletePlan] = useState({
        doctor_id: planCardData?.doctor_id,
        doctor_list_id: planCardData?.doctor_list_id,
        doctor_fee_plan_id: planCardData?.doctor_fee_plan_id
    });

    const DeleteDoctorPlan = async () => {
        RendenDataAfterDelete(false);
        try {
            let response = await axiosInstance.post(
                "/sec/createUpdatedoctorlisting/planDelete",
                JSON.stringify(deletePlan),
            );
            RendenDataAfterDelete(true);
            alert("Deleted : " , response)
            console.log("Delete Api  : ", response);
        } catch (error) {
            console.log("Delete Api : ", error);
            RendenDataAfterDelete(false);
        }
    };


    return (
        <>
            <div className="Box1">
                <div>
                    <div className="detail-type1" key={index}>
                        <Typography
                            style={{
                                fontFamily: "poppins",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "28px",
                                color: "#313033",
                            }}
                        >
                            {planCardData?.plan_name}
                        </Typography>
                        <Typography
                            style={{
                                fontFamily: "poppins",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "18px",
                                color: "#787579",
                            }}
                        >
                            {planCardData?.plan_fee} | {planCardData?.plan_duration}
                        </Typography>
                    </div>
                </div>
                <div className="Delete-Edit">
                    <div className="Delete-Icon" style={{display : isDeleteVisible ? 'block' : 'none'}} >
                        <Box
                            sx={{
                                // border:'1px solid',
                                marginTop: "0.5rem",
                                marginLeft: "5%",
                                
                            }}
                        >
                            <DeleteIcon />
                        </Box>
                        <CustomButton
                            label="Delete"
                            isTransaprent={"True"}
                            handleClick={() => {
                                DeleteDoctorPlan();
                            }}
                            buttonCss={{
                                borderBottom: "1px",
                                borderLeft: "1px",
                                borderRight: "1px",
                                borderTop: "1px",
                            }}
                        ></CustomButton>
                    </div>
                    <div className="Edit-Icon" style={{display : isEditVisible ? 'block' : 'none'}} >
                        <Box
                            sx={{
                                // border:'1px solid',
                                marginTop: "0.5rem",
                                marginLeft: "5%",
                            }}
                        >
                            <EditIcon />
                        </Box>
                        <CustomButton
                            label="Edit"
                            isTransaprent={"True"}
                            buttonCss={{
                                borderBottom: "1px",
                                borderLeft: "1px",
                                borderRight: "1px",
                                borderTop: "1px",
                            }}
                        ></CustomButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddPlanCard;
