/* eslint-disable space-in-parens */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import React, { useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import image1 from "./DrImages/image1.png";
import image2 from "./DrImages/image2.png";
import image3 from "./DrImages/image3.png";
import Drcard from "./drcard/drcard";
import CustomButton from "../components/CustomButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Skeleton from "react-loading-skeleton";
import NoAppointmentCard from "../Dashboard/PatientAppointment/NoAppointmentCard/NoAppointmentCard";
import HCFDrCard from "../pages/PatientModule/PatientHCF/DrDetailsCard/Labs/HCFDrCard";

export const baseURL = "http://localhost:3000";
export const front_end_url = "http://localhost:8000";
export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,55}$/;
export const numberRegex = /^\d+$/;

export const data = [
    {
        id: 1,
        drimage: image1,
        name: "Dr. Elizabeth Davis",
        hospital: "Xyz Hospital",
        specialist: "Neurologist",
        rating: "4.5",
        reviews: "(200) Review",
    },
    {
        id: 2,
        drimage: image2,
        name: "Dr. William",
        hospital: "Gangaram Hospital",
        specialist: "Pediatric",
        rating: "4.3",
        reviews: "(400) Review",
    },
    {
        id: 3,
        drimage: image3,
        name: "Dr. Arbaaz Khan",
        hospital: "RML Hospital",
        specialist: "Nutritionist",
        rating: "3.9",
        reviews: "(900) Review",
    },
];

export const CallCardData = ({ sendCardData, textField, linkPath }) => {
    console.log("This is the data send by card", sendCardData);

    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 200; // You can adjust the scrolling amount
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 200; // You can adjust the scrolling amount
        }
    };

    return (
        <Box sx={{ width: "100%", marginTop: "1%" }}>
            <Box>
                <Typography>{textField}</Typography>
            </Box>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    paddingRight: "",
                }}
            >
                <IconButton aria-label="Scroll left" onClick={scrollLeft}>
                    <ChevronLeftIcon />
                </IconButton>
                <div
                    ref={containerRef}
                    style={{
                        width: "calc(100% - 48px)",
                        overflowX: "auto",
                        display: "flex",
                        scrollbarWidth: "none",
                        scrollBehavior: "smooth",
                    }}
                >
                    <Box sx={{ borderRadius: 1, display: "flex" }}>
                        {sendCardData?.length === 0 || undefined ? (
                            <>
                                <NoAppointmentCard
                                    style={{ height: "8rem" }}
                                    text_one={"No Data found"}

                                />
                            </>
                        ) : (
                            sendCardData === undefined ? <NoAppointmentCard
                                style={{ height: "8rem" }}
                                text_one={"No Data found"}
                            /> : 
                            sendCardData.slice(0, 10).map((dataprop, index) => (
                                <Link
                                    to={linkPath + dataprop.suid}
                                    style={{
                                        width: "20em",
                                        textDecoration: "none",
                                        marginRight: "10px",
                                    }}
                                    key={index}
                                >
                                    <Drcard DrData={dataprop} />
                                </Link>
                            ))
                        )}
                    </Box>
                </div>
                <IconButton aria-label="Scroll right" onClick={scrollRight}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
            {/* <Box sx={{ borderRadius: 1, width: "100%", display: "flex" }}>
                {sendCardData.map((dataprop, index) => {
                    if (index <= 2) {
                        return (
                            <Link
                                to={linkPath + dataprop.suid}
                                style={{ width: "33%", textDecoration: "none" }}
                            >
                                <Drcard DrData={dataprop} />
                            </Link>
                        );
                    }
                    return null;
                })}
            </Box> */}
        </Box>
    );
};

export const currencysign = "₹";

export const patient_suid = localStorage.getItem("patient_suid");

export const PatientCarousalCards = () => {
    <Box
        sx={{
            borderRadius: "8px",
            border: "1px solid #E6E1E5",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
        }}
    >
        {/* content Box */}
        <Box
            sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-around",
            }}
        >
            <Box sx={{ marginLeft: "5%" }}>
                <Typography sx={{ textAlign: "start" }}>
                    Take control of your health with our user-friendly health care app.
                </Typography>
            </Box>
            <Box sx={{ marginLeft: "5%" }}>
                <CustomButton
                    buttonCss={{ border: "none" }}
                    label="Book Now"
                    isTransaprent={true}
                />
            </Box>
        </Box>
        {/* Image Box */}
        <Box sx={{ width: "40%" }}>
            {/* <Box component={'img'} src={tileData.image1} ></Box> */}
            <img
                src={"https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png"}
                alt="/"
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "14px",
                    padding: "4px",
                }}
            ></img>
        </Box>
    </Box>;
};


export const HCFCardsData = ({ sendCardData, textField, linkPath }) => {
    console.log("HCF data send by card", sendCardData);

    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 200; // You can adjust the scrolling amount
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 200; // You can adjust the scrolling amount
        }
    };

    return (
        <Box sx={{ width: "100%", marginTop: "1%" }}>
    
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    paddingRight: "",
                }}
            >
                <IconButton aria-label="Scroll left" onClick={scrollLeft}>
                    <ChevronLeftIcon />
                </IconButton>
                <div
                    ref={containerRef}
                    style={{
                        width: "calc(100% - 48px)",
                        overflowX: "auto",
                        display: "flex",
                        scrollbarWidth: "none",
                        scrollBehavior: "smooth",
                    }}
                >
                    <Box sx={{ borderRadius: 1, display: "flex" , width : "100%" }}>
                        {sendCardData?.length === 0 || undefined ? (
                            <>
                                <NoAppointmentCard
                                    style={{ height: "8rem" }}
                                    text_one={"No Data found"}

                                />
                            </>
                        ) : (
                            sendCardData === undefined ? <NoAppointmentCard
                                style={{ height: "8rem" }}
                                text_one={"No Data found"}
                            /> : 
                            sendCardData.slice(0, 10).map((dataprop, index) => (
                            
                                    <HCFDrCard data={dataprop} />
                            ))
                        )}
                    </Box>
                </div>
                <IconButton aria-label="Scroll right" onClick={scrollRight}>
                    <ChevronRightIcon />
                </IconButton>
            </div>
        </Box>
    );
};