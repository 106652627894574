import React from "react";


const DiagnostLabs = () => {
    return(
        <>
            <h1>This is diagnostic labs</h1>
        </>
    )
};

export default DiagnostLabs;