import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PatientSearchTable } from "../DiagnosticPatientSearch/PatientSearchTable";
import { ShareListModal } from "./ShareListModal";
import axiosInstance from "../../../../config/axiosInstance";
import DiagnostCenterTableCard from "./DiagnostCenterTableCard";

function createData(name, calories, fat, carbs, protein, action) {
    return { name, calories, fat, carbs, protein, action };
}
const rows = [
    createData(
        <PatientSearchTable name={"Patient Name"} Id={"001"} />,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <ShareListModal />,
        <div>
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),
    createData(
        <PatientSearchTable name={"Patient Name"} Id={"001"} />,
        <Typography>19:00, 23-10-23</Typography>,
        <Typography>Rad-1</Typography>,
        <ShareListModal />,
        <div>
            <MoreHorizIcon
                style={{
                    color: "grey",
                }}
            />
        </div>,
    ),
];
const Shared = () => {
    const [cardData, setCardData] = useState([]);
    const [staff_id , setStaff_id] = useState(localStorage.getItem('diagnostic_suid'))

    const fetchData = async () => {
      try{
          const resp = await axiosInstance(`/sec/hcf/reportShared/${staff_id}`);
          console.warn("Shared : ", resp)
          setCardData(resp?.data?.response);
      }catch(err){
          console.log("Error : ", err)
      }
    }
    
    useEffect( () => {
      fetchData();
    },[] )

    const memoizedCardData = useMemo( () =>  {
        return cardData.map( data => 
            ({
                id : data?.suid,
                name : `${data?.first_name} ${data?.last_name}`,
                bookDate : data?.book_date || "N/A",
                testName : data?.test_name || "N/A",
                status : data?.status || "N/A",
                testFile  : data?.report_path || "N/A"
            })
         )  
        },[cardData] ) 

    
    return (
        <>
            <TableContainer component={Paper} style={{ background: "white" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name/Booking ID</TableCell>
                            <TableCell align="right">Date & time</TableCell>
                            <TableCell align="right">Test Name</TableCell>
                            <TableCell align="right">File Name</TableCell>
                            <TableCell align="right">Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memoizedCardData?.map((data) => (
                            <TableRow
                                key={data?.suid}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                {/* {`${data?.first_name} ${data?.last_name} ${data?.suid}`} */}
                                    <DiagnostCenterTableCard id={data?.id} name={data?.name}/>

                                </TableCell>
                                <TableCell align="right">{data?.bookDate}</TableCell>
                                <TableCell align="right">{data?.testName }</TableCell>
                                <TableCell align="right">{data?.testFile}</TableCell>
                                <TableCell align="right">{data?.status }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
export default Shared;
