import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import DoctorListingNavbar from "../../CustomDoctorComponent/DoctorListingNavbar/DoctorListingBavbar";
import "./doctorSavedDraft.scss";
import CustomButton from "../../../components/CustomButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomDrActiveListingCard from "../../CustomDoctorComponent/Cards/CustomDrActiveListingCard/CustomDrActiveListingCard";
import CustomSnackBar from "../../../components/CustomSnackBar";
import NoAppointmentCard from "../../../Dashboard/PatientAppointment/NoAppointmentCard/NoAppointmentCard";
import axiosInstance from "../../../config/axiosInstance";

const DoctorSavedDraft = () => {
    const [activeListing , setActiveListing] = useState([]);
    const [deleteDoctorListFlag , setDeleteDoctorListFlag] = useState(false);
    const [deleteListing , setDeleteListing] = useState(false);
    const [snackmessage , setSnackmessage] = useState("")
    React.useEffect(() => {
        localStorage.setItem("activeComponent", "listing");
        localStorage.setItem("path", "doctorsavedInDraft");
    }, []);
    const [cards, setCards] = useState([
        { id: 1, label: "Card 1", Idtype: "Type 1", Idnumber: "Number 1" },
        { id: 2, label: "Card 2", Idtype: "Type 2", Idnumber: "Number 2" },
        // ... other cards
    ]);

    const handleDelete = (id) => {
        const updatedCards = cards.filter((card) => card.id !== id);
        setCards(updatedCards);
    };

    const fetchActiveListing = async () => {
        try {
            const response = await axiosInstance.get(`/sec/doctor/DocListingPlanDeactive/${localStorage.getItem("doctor_suid")}`);
            console.log("Active Listing : ", response?.data?.DocListingPlanDeactive);
            setActiveListing(response?.data?.DocListingPlanDeactive);
            setDeleteDoctorListFlag(false);
        } catch (err) {
            console.log("Active listing error : ", err);
            setDeleteDoctorListFlag(false);
        }
    };

    const DeleteDoctorListing = async (listID) => {
        setDeleteListing(false);
        setDeleteDoctorListFlag(false);
        try{
            const response = await axiosInstance.post("/sec/doctor/deleteDocListingPlan",{
                "doctor_id": localStorage.getItem("doctor_suid") ,
                doctor_list_id : listID
            })
            if(response.status === 200 || response.status === 202){
                setDeleteDoctorListFlag(true)
                setDeleteListing(true);
            }
            console.log("Delete : " , response)
        }catch(err){
            console.log("Error" , err);
            setDeleteDoctorListFlag(false)
            setDeleteListing(false);
        }
    }


    const ChangeActiveState = async (doctor_id, doctor_list_id) => {  // api for deactivation
        setDeleteDoctorListFlag(false);
        try {
            const response = await axiosInstance.post(`/sec/doctor/docListingActiveDeactive`, {
                doctor_id: doctor_id,
                doctor_list_id: doctor_list_id,
                is_active: 1, // activate
            });
            setSnackmessage(response?.data?.response?.message);
            setDeleteDoctorListFlag(true);
        } catch (err) {
            console.log("DeActivate Error : ", err);
            setDeleteDoctorListFlag(false);
        }
    };

    useEffect( () => {
        if(deleteDoctorListFlag){
            fetchActiveListing();
        }
    } , [deleteDoctorListFlag] )

    useEffect(() => {
        fetchActiveListing();
    }, []);

    return (
        <>
            <CustomSnackBar message={snackmessage} isOpen={deleteDoctorListFlag} type={"success"}/>
            <Box
                sx={{
                    display: "flex",
                    width: "95%",
                    height: "100%",
                    height: "90%",
                    // border: "1px solid",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <DoctorListingNavbar />
                    </Box>
                    <div className="">
                        {/* <CustomButton
                            label="Create New"
                            isTransaprent={"True"}
                            buttonCss={{
                                width: "170px",
                                borderRadius: "20px",
                            }}
                            handleClick={() => {
                                navigate("/doctordashboard/doctorListing/listingdetails");
                            }}
                        ></CustomButton> */}
                        {/* <MoreHorizIcon
                            style={{
                                color: "grey",
                            }}
                        /> */}
                    </div>
                </Box>
                <Box
                    component={"div"}
                    sx={{
                        position: "relative",
                        top: "4em",
                        width: "100%",
                        display: "flex",
                        height: "100%",
                    }}
                >
                    <Box
                        component={"div"}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            height: "80%",
                        }}
                    >
                        { activeListing.length === 0 ? <NoAppointmentCard text_one={"No Listing found"} /> :  activeListing.map((card) => (
                            <CustomDrActiveListingCard
                                buttonOneLabel={"Delete"}
                                key={card?.doctor_list_id}
                                label={card?.listing_name}
                                // Idtype={"card?.Idtype"}
                                Idtype={"Listing ID"}
                                Idnumber={card?.doctor_list_id}
                                onhandleClickButtonOne={() => DeleteDoctorListing(card?.doctor_list_id)}
                                buttonTwoLabel={"Activate"}
                                onhandleClickButtonTwo={() =>
                                        ChangeActiveState(card?.doctor_id, card?.doctor_list_id)
                                    }
                            />
                        ))}
                        {/* <CustomDrActiveListingCard label={"Secondary Listing"} Idtype={"Listing ID"} Idnumber={"0000123456"} /> */}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DoctorSavedDraft;
