/* eslint-disable prettier/prettier */
/* eslint-disable prettier/prettier */

/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable prettier/prettier */
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../components/CustomTextField/custom-text-field";
import CustomDropdown from "../../components/CustomDropdown/custom-dropdown";
import Avatar from "@mui/material/Avatar";
import CustomButton from "../../components/CustomButton/custom-button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./profile.scss";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";

const Profile = () => {
    const [activeDropdown, setActiveDropdown] = useState("");
    const [profileLink, setProfileLink] = useState("");
    const [contactLink, setContactLink] = useState("");
    const [profileUpdate, setProfileUpdate] = useState({
        email: localStorage.getItem("patient_Email"),
        first_name: null,
        last_name: null,
        middle_name: null,
        added_by: "self",
        gender: null,
        DOB: null,
    });
    const navigate = useNavigate;
    const handleSubmit = (e) => {
        fetchData();
    };

    const fetchData = async () => {
        try {
            const response = await axiosInstance.post(
                "/sec/updatePateintProfile",
                JSON.stringify(profileUpdate),
            );
            alert("Changed succesfully ID hardcoded")
            console.log("Success  : " ,response)
        } catch (error) {
            console.log(error);
        }
    };

    console.log("Profile Update : ",profileUpdate)

    const fetchDataProfile = async () => {
        try {
            const response = await axiosInstance.post(
                "/sec/patientprofile",
                JSON.stringify({
                    "suid":localStorage.getItem("patient_suid")
                }),
            );
            console.log("Patient Profile Details : " , response?.data?.response[0]);
            setProfileUpdate({
                email: response?.data?.response[0]?.email,
                first_name: response?.data?.response[0]?.first_name,
                last_name: response?.data?.response[0]?.last_name,
                middle_name: response?.data?.response[0]?.middle_name,
                added_by:  "self",
                gender: response?.data?.response[0]?.gender,
                DOB: response?.data?.response[0]?.DOB,
            })
        } catch (error) {
            console.log(error);
        }
    };


    
    useEffect(() => {
        setProfileLink(
            localStorage.getItem("activeComponent") === "dashboard"
                ? "/patientdashboard/dashboard/profile"
                : localStorage.getItem("activeComponent") === "appointment"
                ? "/patientdashboard/appointment/profile"
                : localStorage.getItem("activeComponent") === "manage"
                ? "/patientdashboard/manage/profile"
                : null,
        );

        setContactLink(
            localStorage.getItem("activeComponent") === "dashboard"
                ? "/patientdashboard/dashboard/contact"
                : localStorage.getItem("activeComponent") === "appointment"
                ? "/patientdashboard/appointment/contact"
                : localStorage.getItem("activeComponent") === "manage"
                ? "/patientdashboard/manage/contact"
                : null,
        );
        fetchDataProfile();
    }, []);
    
    return (
        <Box sx={{ width: "100%" }}>
            <Box
                className="NavBar-Box-profile"
                sx={{ display: "flex", marginLeft: 0, marginBottom: 0 }}
            >
                <NavLink to={profileLink}>Profile Information</NavLink>
                <NavLink to={contactLink}>Contact Details</NavLink>
                {/* <NavLink to={"/patientdashboard/dashboard/payment"}>Payment Details</NavLink> */}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    height: "100%",
                    marginTop: "4%",
                }}
            >
                {/* imageBox */}
                <Box sx={{ width: "18%", height: "100%" }}>
                    <Box sx={{ width: "170px", height: "170px" }}>
                        <Avatar
                            alt="Remy Sharp"
                            src="images/avatar.png"
                            sx={{ width: "100%", height: "100%" }}
                        />
                    </Box>
                </Box>
                {/* content Box */}
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        height: "100%",
                        width: "82%",
                    }}
                >
                    {/* First line of inputs */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "70%",
                            height: "100%",
                        }}
                    >
                        <Box sx={{ marginRight: "2%" }}>
                            <CustomTextField
                                id={"standard-helperText1"}
                                label={"First Name"}
                                defaultValue={profileUpdate?.first_name}
                                CustomValue={profileUpdate?.first_name}
                                helperText={""}
                                isValid
                                // eslint-disable-next-line no-undef
                                onChange={(event) => {
                                    setProfileUpdate({
                                        ...profileUpdate,
                                        first_name: event?.target?.value,
                                    });
                                }}
                                textcss={{
                                    width: "350px",
                                    height: "56px",
                                }}
                            />
                        </Box>
                        <Box>
                            <CustomTextField
                                id={"standard-helperText1"}
                                label={"Middle Name"}
                                defaultValue={profileUpdate?.middle_name}
                                CustomValue={profileUpdate?.middle_name}
                                helperText={""}
                                isValid
                                // eslint-disable-next-line no-undef
                                onChange={(event) => {
                                    setProfileUpdate({
                                        ...profileUpdate,
                                        middle_name: event?.target?.value,
                                    });
                                }}
                                // onChange={(event) => setMobile(event.target.value) }
                                textcss={{
                                    width: "350px",
                                    height: "56px",
                                }}
                            />
                        </Box>
                    </Box>
                    {/* //second line  */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "70%",
                            height: "100%",
                        }}
                    >
                        <Box sx={{ marginRight: "2%" }}>
                            <CustomTextField
                                id={"standard-helperText1"}
                                label={"Last Name"}
                                defaultValue={profileUpdate?.last_name}
                                CustomValue={profileUpdate?.last_name}
                                helperText={""}
                                isValid
                                // eslint-disable-next-line no-undef
                                onChange={(event) => {
                                    setProfileUpdate({
                                        ...profileUpdate,
                                        last_name: event?.target?.value,
                                    });
                                }}
                                textcss={{
                                    width: "350px",
                                    height: "56px",
                                }}
                            />
                        </Box>
                        <Box>
                            <Grid container justifyContent="space-around">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        variant="dashed"
                                        openTo="year"
                                        views={["year", "month", "day"]}
                                        // value={profileUpdate?.DOB}
                                        label="Date Of Birth"
                                        onChange={(item) => {
                                            setProfileUpdate({ ...profileUpdate, DOB: item });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Box>
                    </Box>
                    {/* dropdown */}
                    <Box sx={{ display: "flex" }}>
                        <CustomDropdown
                            label={"Gender"}
                            items={["Male", "Female", "Rather Not Say"]}
                            activeItem={activeDropdown}
                            menuItemValue={profileUpdate?.gender}
                            handleChange={(listItems) => {
                                setProfileUpdate({ ...profileUpdate, gender: listItems });
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", marginTop: "6%" }}>
                        <CustomButton
                            label={"Save Changes"}
                            isTransaprent={false}
                            isDisabled={false}
                            isElevated={false}
                            handleClick={handleSubmit}
                            buttonCss={{
                                width: "155px",
                                height: "41px",
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Profile;

// import React, { useState } from "react";

// const MyActivity = () => {
//     return (
//         <>
//             <h1>My Activity</h1>
//         </>
//     );
// };

// export default MyActivity;
